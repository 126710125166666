// Core React libraries import
import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Space,
    Menu,
    Button,
    Tabs,
    Input,
    Form,
    Typography,
    Dropdown,
    Badge,
    Tag,
    Tooltip,
    Segmented,
    Select,
    Divider,
    Modal,
    Popover,
    Switch,
    message,
} from 'antd';
import { RenderExpandIcon } from 'rc-table/lib/interface';
import {
    DeleteOutlined,
    PlusOutlined,
    SaveOutlined,
    DownOutlined,
    MenuUnfoldOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    RightOutlined,
    FieldTimeOutlined,
    MessageOutlined,
    CloudServerOutlined,
    CloseOutlined,
    SaveFilled,
    EditOutlined,
    FileSearchOutlined,
    InfoCircleFilled,
    EllipsisOutlined,
    PrinterOutlined,
    CaretDownOutlined,
    MailOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    InfoCircleOutlined,
    EnterOutlined,
    HolderOutlined,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import moment from 'moment';
import qs from 'qs';
import { useReactToPrint } from 'react-to-print';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

// EZ web-app utils import
import { showMessage, showNotification } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    getTransactionTasks,
    getTransactionTaskLists,
    updateTask,
    getAllTasks,
    linkTasksToDocs,
    deleteTask,
    addNewTask,
    addNewTasklist,
    updateTasklist,
    deleteTasklist,
} from 'utils/ez_api/taskAPIs';
import {
    addNewTransactionDoc,
    getPresignedURLContractDocUpload,
    getTransactionDocs,
} from 'utils/ez_api/documentAPIs';
import { getAllTeamMembers } from 'utils/ez_api/memberAPIs';
// EZ web-app types import
import {
    ACTIVE_STATUS_IDS,
    TransactionDocItemType,
    TransactionTaskItemType,
    TransactionTaskListType,
} from 'types/transaction';
import { AccountCompactDataType } from 'types/account';
// EZ web-app components import
import { HomeContext } from 'context/EZContext';
import { TAB_CATEGORY } from 'types/webAppTab';
import { EZTable, EZDateFormat, EZSelectDocument, EZSelectStatus } from 'components';
import {
    forwardRefEZTableProps,
    IOnChangeCallbackProps,
    ITableQueryParams,
} from 'components/table/EZProTable';
import { previewDoc } from './documents';
import { EditTaskModalContent } from './modals/taskEditDetail';
import { AddTasklistModalContent } from './modals/tasklistAddContent';
import { TaskEmailModalContent } from './modals/taskEmailModal';
import {
    SortableContext,
    arrayMove,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { bulkUpdateTaskTransaction } from 'utils/ez_api/transactionAPIs';
import { cloneDeep } from 'lodash';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;
const { Option } = Select;

const defaultTableParam: ITableQueryParams = {
    searchKeyword: '',
    orderParams: [
        ['ow', 'asc'],
        ['d_created', 'desc'],
        ['prio', 'desc'],
    ],
    whereParams: {},
    whereFilterParams: {},
    silentLoading: false,
};

const defaultTableParamMainTaskMenu: ITableQueryParams = {
    searchKeyword: '',
    orderParams: [
        ['duedate', 'desc'],
        ['prio', 'desc'],
    ],
    whereParams: {},
    whereFilterParams: {},
    silentLoading: false,
};

const menu = (
    <Menu items={[{ key: '12', label: `Edit Task Detail`, icon: <MenuUnfoldOutlined /> }]} />
);

export enum TASK_EMBEDDED_TYPE {
    TRANSACTION_DETAIL = 'transaction_detail',
    MAIN_MENU = 'main_menu',
    TRANSACTION_LIST = 'transaction_list',
}

type BULK_ACTIONS = 'MOVE_TO_TASKLIST' | 'SET_DUE_DATE' | 'DELETE';

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined />}
            style={{ cursor: 'move' }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

const RowTable: React.FC<RowProps> = props => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props['data-row-key'] });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 1 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners]
    );

    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

export const AppPageTasks: React.FC<{
    pageType: TASK_EMBEDDED_TYPE;
    contractID?: number;
    isTransactionDeleted?: boolean;
    contractAddress?: string;
    manualReloadCount: number;
    reloadTransactionStat?: (contract_id: number) => void;
}> = ({
    contractID = 0,
    contractAddress,
    manualReloadCount,
    isTransactionDeleted,
    pageType,
    reloadTransactionStat,
}) => {
    const HomeCtx = useContext(HomeContext);
    const UserPermission = HomeCtx.userPermission;

    const EZTableRef = useRef<forwardRefEZTableProps>();
    const componentRef = useRef<HTMLDivElement>(null);

    const [form] = Form.useForm();

    const nowMoment = moment();

    defaultTableParam.whereFilterParams = {};
    defaultTableParam.whereFilterParams['compl'] = 0;

    defaultTableParamMainTaskMenu.whereFilterParams['compl'] = 0;
    defaultTableParamMainTaskMenu.whereFilterParams['duedate'] = nowMoment.format('YYYY-MM-DD');

    const isMainMenuTaskTab = !contractID || contractID <= 0;
    const [taskListMenu, setTaskListMenu] = useState<TransactionTaskListType[]>([]);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Tasklist - ${contractAddress}`,
    });

    const getDefaultTableParam = (isMainMenuTaskTab: boolean): ITableQueryParams => {
        return isMainMenuTaskTab ? defaultTableParamMainTaskMenu : defaultTableParam;
    };
    const [queryParams, setQueryParams] = useState<ITableQueryParams>(
        getDefaultTableParam(isMainMenuTaskTab)
    );

    const [taskTypeFilter, setTaskTypeFilter] = useState<string>('incomplete_tasks');
    const [filterType, setFilterType] = useState<string>('today');
    const [transactionStatusFilter, setTransactionStatusFilter] = useState<string>();
    const [taskMenuFilter, setTaskMenuFilter] = useState<string>();
    const [toggleMyTasks, setToggleMyTasks] = useState<boolean>(false);

    const [toggleAllRows, setToggleAllRows] = useState<boolean>(
        pageType === TASK_EMBEDDED_TYPE.TRANSACTION_DETAIL ? true : false
    );
    const [tableRecordIDs, setTableRecordIDs] = useState<React.Key[]>([]);
    const [expandedRecordIDs, setExpandedRecordIDs] = useState<React.Key[]>([]);

    const [isUpdatingTask, setIsUpdatingTask] = useState<boolean>(false);
    const [disableDrag, setDisableDrag] = useState<boolean>(false);

    const [teamMembers, setTeamMembers] = useState<AccountCompactDataType[]>([]);
    const [transactionTasks, setTransactionTasks] = useState<TransactionTaskItemType[]>([]);

    async function _fetchData(EZTableQueryParams: string): Promise<any> {
        if (!isMainMenuTaskTab) {
            const tableParams = new URLSearchParams(EZTableQueryParams);

            if (tableParams.get('order[0][0]') !== 'ow') {
                setDisableDrag(true);
            } else {
                setDisableDrag(false);
            }
            return getTransactionTasks(contractID, EZTableQueryParams).then(result => {
                setTransactionTasks(result.data);
                return result;
            });
        } else {
            return getAllTasks(EZTableQueryParams).then(result => result);
        }
    }

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setTransactionTasks(prevState => {
                const activeIndex = prevState.findIndex(record => record.id === active?.id);
                const overIndex = prevState.findIndex(record => record.id === over?.id);
                let payload: { uuid: string; ow: number }[] = [];
                if (activeIndex > overIndex) {
                    //move up
                    let seen = new Set();
                    // check if in tab there is any duplicated position order
                    // will regroup the position order if found any
                    const isPositionOrderDuplicate = prevState.some(function (currentObject) {
                        return seen.size === seen.add(currentObject.ow).size;
                    });
                    if (isPositionOrderDuplicate) {
                        //some duplicate
                        const payloadDuplicate = prevState.map(
                            (task: TransactionTaskItemType, idx: number) => {
                                prevState[idx].ow = idx;
                                return {
                                    uuid: task.uuid,
                                    ow: idx,
                                };
                            }
                        );
                        bulkUpdateTaskTransaction(contractID, payloadDuplicate)
                            .then(() => {
                                const tempTask = cloneDeep(prevState);
                                for (let index = overIndex; index <= activeIndex; index++) {
                                    if (index === activeIndex) {
                                        payload.push({
                                            uuid: tempTask[index].uuid,
                                            ow: tempTask[overIndex].ow,
                                        });
                                        prevState[index].ow = tempTask[overIndex].ow;
                                    } else {
                                        payload.push({
                                            uuid: tempTask[index].uuid,
                                            ow: tempTask[index + 1].ow,
                                        });
                                        prevState[index].ow = tempTask[index + 1].ow;
                                    }
                                }
                                bulkUpdateTaskTransaction(contractID, payload).then(() => {
                                    return arrayMove(prevState, activeIndex, overIndex);
                                });
                            })
                            .catch(err => {
                                message.error(err);
                                return arrayMove(prevState, activeIndex, overIndex);
                            });
                    } else {
                        //not duplicate
                        const tempTask = cloneDeep(prevState);
                        for (let index = overIndex; index <= activeIndex; index++) {
                            if (index === activeIndex) {
                                payload.push({
                                    uuid: tempTask[index].uuid,
                                    ow: tempTask[overIndex].ow,
                                });
                                prevState[index].ow = tempTask[overIndex].ow;
                            } else {
                                payload.push({
                                    uuid: tempTask[index].uuid,
                                    ow: tempTask[index + 1].ow,
                                });
                                prevState[index].ow = tempTask[index + 1].ow;
                            }
                        }
                        bulkUpdateTaskTransaction(contractID, payload).then(() => {
                            return arrayMove(prevState, activeIndex, overIndex);
                        });
                    }
                } else {
                    //move down
                    let seen = new Set();
                    // check if in tab there is any duplicated position order
                    // will regroup the position order if found any
                    const isPositionOrderDuplicate = prevState.some(function (currentObject) {
                        return seen.size === seen.add(currentObject.ow).size;
                    });
                    if (isPositionOrderDuplicate) {
                        //some duplicate
                        const payloadDuplicate = prevState.map(
                            (task: TransactionTaskItemType, idx: number) => {
                                prevState[idx].ow = idx;
                                return {
                                    uuid: task.uuid,
                                    ow: idx,
                                };
                            }
                        );
                        bulkUpdateTaskTransaction(contractID, payloadDuplicate)
                            .then(() => {
                                const tempTask = cloneDeep(prevState);
                                for (let index = activeIndex; index <= overIndex; index++) {
                                    if (index === activeIndex) {
                                        payload.push({
                                            uuid: tempTask[index].uuid,
                                            ow: tempTask[overIndex].ow,
                                        });
                                        prevState[index].ow = tempTask[overIndex].ow;
                                    } else {
                                        payload.push({
                                            uuid: tempTask[index].uuid,
                                            ow: tempTask[index - 1].ow,
                                        });
                                        prevState[index].ow = tempTask[index - 1].ow;
                                    }
                                }
                                bulkUpdateTaskTransaction(contractID, payload).then(() => {
                                    return arrayMove(prevState, activeIndex, overIndex);
                                });
                            })
                            .catch(err => {
                                message.error(err);
                                return arrayMove(prevState, activeIndex, overIndex);
                            });
                    } else {
                        //not duplicate
                        const tempTask = cloneDeep(prevState);
                        for (let index = activeIndex; index <= overIndex; index++) {
                            if (index === activeIndex) {
                                payload.push({
                                    uuid: tempTask[index].uuid,
                                    ow: tempTask[overIndex].ow,
                                });
                                prevState[index].ow = tempTask[overIndex].ow;
                            } else {
                                payload.push({
                                    uuid: tempTask[index].uuid,
                                    ow: tempTask[index - 1].ow,
                                });
                                prevState[index].ow = tempTask[index - 1].ow;
                            }
                        }
                        bulkUpdateTaskTransaction(contractID, payload).then(() => {
                            return arrayMove(prevState, activeIndex, overIndex);
                        });
                    }
                }
                return arrayMove(prevState, activeIndex, overIndex);
            });
        }
    };
    // expand or collapse the rows
    const _toggleAllRows = async (tAllRows: boolean) => {
        setToggleAllRows(tAllRows);

        if (!tAllRows) {
            setExpandedRecordIDs([]);
        } else {
            setExpandedRecordIDs([...tableRecordIDs]);
        }
    };

    const _expandSelectedRows = (rowKeys: number[]) => {
        const newRowIDs: number[] = [];
        for (let index = 0; index < rowKeys.length; index++) {
            const rowID = rowKeys[index];

            if (!expandedRecordIDs.find(elm => elm === rowID)) newRowIDs.push(rowID);
        }

        if (newRowIDs.length) setExpandedRecordIDs([...expandedRecordIDs, ...newRowIDs]);
    };

    const _onChangeCallback: IOnChangeCallbackProps = (data: TransactionTaskItemType[]) => {
        if (data?.length) {
            if (toggleAllRows) {
                setExpandedRecordIDs(
                    data
                        .filter(
                            record =>
                                record.compl !== 1 &&
                                ((record.note && record.note.trim() !== '') || record.docs?.length)
                        )
                        .map(record => record.id)
                );
            }
            setTableRecordIDs(data.map(record => record.id));
        } else {
            setTableRecordIDs([]);
            setExpandedRecordIDs([]);
        }
    };

    const _expandIcon: RenderExpandIcon<TransactionTaskItemType> = ({
        expanded,
        onExpand,
        record,
    }) => {
        return expanded ? (
            <DownOutlined
                className="no-print"
                id={`ez-tr-icon-expandable-c_${contractID}-task_${record.id}`}
                onClick={(e: any) => onExpand(record, e)}
            />
        ) : (
            <RightOutlined
                className="no-print"
                id={`ez-tr-icon-expandable-c_${contractID}-task_${record.id}`}
                onClick={(e: any) => onExpand(record, e)}
            />
        );
    };

    const _reloadSubTabData = (resetPagination?: boolean, silentLoading?: boolean) => {
        setQueryParams({
            ...queryParams,
            resetPagination,
            silentLoading: silentLoading || undefined,
        });
    };

    const _fetchTaskListMenu = () => {
        getTransactionTaskLists(contractID, { pageSize: 50 }).then(resp => {
            if (resp.data) {
                setTaskListMenu(resp.data);
            }
        });
    };

    const _saveTaskData = async (
        newData: TransactionTaskItemType,
        oldData: TransactionTaskItemType,
        formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
    ) => {
        setIsUpdatingTask(true);

        if (newData.id) {
            let dataIdx = transactionTasks?.findIndex(item => item.id === newData.id);

            newData.assignee_account = newData.assignee
                ? teamMembers.find(member => member.account_id === newData.assignee)
                : undefined;
            if (dataIdx !== -1) {
                let copyTask = [...transactionTasks];
                copyTask[dataIdx] = newData;
                setTransactionTasks(copyTask);
            }

            EZTableRef.current?.modifyData(newData, newData.id);
        }

        return updateTask(
            oldData.task_list.contract_id,
            newData.id,
            {
                ...newData,
            },
            formType
        )
            .then(() => {
                // EZTableRef.current?.reload();
                if (contractID) {
                    reloadTransactionStat?.(contractID);
                }

                if (newData.compl === 1 && oldData.compl === 0) {
                    showNotification(
                        'success',
                        `Task: "${newData.title}" has been marked as complete.`
                    );
                } else if (newData.compl === 0 && oldData.compl === 1) {
                    showNotification(
                        'success',
                        `Task: "${newData.title}" has been marked as incomplete.`
                    );
                } else {
                    showNotification(
                        'success',
                        `Task: "${newData.title}" has been successfully updated.`
                    );
                }

                if (oldData.list_id !== newData.list_id) {
                    _reloadSubTabData(false, true);
                }

                if (
                    newData.id &&
                    newData.compl !== 1 &&
                    (newData.note?.length || newData.docs?.length)
                ) {
                    setExpandedRecordIDs([...expandedRecordIDs, newData.id]);
                }

                setIsUpdatingTask(false);

                return newData;
            })
            .catch(err => {
                setIsUpdatingTask(false);
                throw err;
            });
    };

    const _saveNewTaskData = async (
        payload: TransactionTaskItemType,
        formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
    ) => {
        setIsUpdatingTask(true);

        return addNewTask(contractID, payload, formType)
            .then(() => {
                if (contractID) {
                    reloadTransactionStat?.(contractID);
                }

                showNotification(
                    'success',
                    `New task: "${payload.title}" has been successfully added.`
                );

                _reloadSubTabData(false, true);

                setIsUpdatingTask(false);

                return payload;
            })
            .catch(err => {
                setIsUpdatingTask(false);
                throw err;
            });
    };

    const _renderPriorityBadge = (record: TransactionTaskItemType) => {
        switch (record.prio) {
            case -1:
                return <Badge count={'-1'} style={{ backgroundColor: '#2db7f5' }} />;
            case 1:
                return <Badge count={'+1'} style={{ backgroundColor: 'gold' }} />;
            case 2:
                return <Badge count={'+2'} style={{ backgroundColor: '#f50' }} />;

            default:
                return <Text type="secondary">±0</Text>;
        }
    };

    const _openNewTab = (record: TransactionTaskItemType) => {
        if (HomeCtx.openTransactionDetailTab) {
            HomeCtx.openTransactionDetailTab(
                record.task_list?.contract_id,
                record.task_list?.contract?.address,
                { ...record.task_list?.contract },
                TAB_CATEGORY.TASK
            );
        }
    };

    const _applyFilter = (
        filterType: string,
        taskTypeFilter: string,
        taskMenuListKey?: string,
        transactionStatusFilter?: string,
        toggleMyTasks?: boolean
    ) => {
        const currentWhereFilterParams: any = {};
        // const currentWhereFilterParams = { ...queryParams.whereFilterParams };
        const nowMoment = moment();

        switch (taskTypeFilter) {
            case 'completed_tasks':
                currentWhereFilterParams['compl'] = 1;
                break;
            case 'all_tasks':
                currentWhereFilterParams['compl'] = undefined;
                break;
            case 'incomplete_tasks':
                currentWhereFilterParams['compl'] = 0;
                break;

            default:
                break;
        }

        if (transactionStatusFilter && transactionStatusFilter === 'all_active_statuses') {
            currentWhereFilterParams['$task_list.contract.status_id$'] = ACTIVE_STATUS_IDS;
        } else {
            currentWhereFilterParams['$task_list.contract.status_id$'] = transactionStatusFilter;
        }

        if (isMainMenuTaskTab) {
            switch (filterType) {
                case 'overdue':
                    currentWhereFilterParams['<duedate'] = nowMoment.format('YYYY-MM-DD');
                    currentWhereFilterParams['compl'] = 0;
                    break;
                case 'today':
                    currentWhereFilterParams['duedate'] = nowMoment.format('YYYY-MM-DD');
                    break;
                case 'this_week':
                    currentWhereFilterParams[`>::<duedate`] = [
                        nowMoment.startOf('week').format('YYYY-MM-DD'),
                        nowMoment.endOf('week').format('YYYY-MM-DD'),
                    ];
                    break;
                case 'next_14_days':
                    currentWhereFilterParams[`>::<duedate`] = [
                        nowMoment.format('YYYY-MM-DD'),
                        nowMoment.add(14, 'days').endOf('day').format('YYYY-MM-DD'),
                    ];
                    break;
                case 'this_month':
                    currentWhereFilterParams[`>::<duedate`] = [
                        nowMoment.startOf('month').format('YYYY-MM-DD'),
                        nowMoment.endOf('month').format('YYYY-MM-DD'),
                    ];
                    break;
                case 'all_tasks':
                    break;

                default:
                    break;
            }
        } else {
            if (taskMenuListKey === '-1') {
                if (currentWhereFilterParams['$task_list.id$']) {
                    delete currentWhereFilterParams['$task_list.id$'];
                }
            } else if (taskMenuListKey) {
                currentWhereFilterParams['$task_list.id$'] = parseInt(
                    taskMenuListKey.replace('tab-tasklist-', '')
                );
            }
        }

        if (toggleMyTasks !== undefined) {
            if (toggleMyTasks) {
                currentWhereFilterParams['assignee'] = HomeCtx.userInfo?.account_id;
            } else {
                delete currentWhereFilterParams['assignee'];
            }
        }
        setQueryParams({
            ...queryParams,
            whereFilterParams: { ...currentWhereFilterParams },
            resetPagination: true,
        });
    };

    async function _fetchPresignURL(payload: any): Promise<any> {
        return getPresignedURLContractDocUpload(contractID, payload);
    }

    const _linkTaskToDocs = async (
        selectedDocs: TransactionDocItemType[],
        task: TransactionTaskItemType
    ) => {
        task.docs = selectedDocs;

        EZTableRef.current?.modifyData(task, task.id);

        return linkTasksToDocs(
            contractID,
            [task.id],
            selectedDocs.map(doc => doc.doc_id)
        )
            .then(result => {
                showNotification(
                    'success',
                    `Linked document(s) on task: "${task.title}" have been successfully updated.`
                );

                return result;
            })
            .catch(err => {
                throw err;
            });
    };

    const _deleteTask = async (record: TransactionTaskItemType) => {
        return deleteTask(record.task_list.contract_id, record.id)
            .then(() => {
                if (reloadTransactionStat && contractID) {
                    reloadTransactionStat(contractID);
                }

                showNotification(
                    'success',
                    `Task: "${record.title}" has been successfully deleted.`
                );
                _reloadSubTabData(false, true);
                setIsUpdatingTask(false);

                return record;
            })
            .catch(err => {
                setIsUpdatingTask(false);
                throw err;
            });
    };

    const _onSaveTask = async ({
        data,
        oldData,
    }: {
        data: TransactionTaskItemType;
        oldData: TransactionTaskItemType;
    }) => {
        let newDate = null;

        if (data.duedate) {
            let newDateMoment = moment(data.duedate, 'MMM Do, YYYY', true);

            if (newDateMoment.isValid()) {
                newDate = newDateMoment.format('YYYY-MM-DD');
            } else {
                newDateMoment = moment(data.duedate, 'YYYY-MM-DD', true);
                if (newDateMoment.isValid()) {
                    newDate = newDateMoment.format('YYYY-MM-DD');
                }
            }
        }

        return await _saveTaskData(
            {
                ...data,
                assignee_account: data.assignee
                    ? teamMembers.find(member => member.account_id === data.assignee)
                    : undefined,
                duedate: newDate,
            },
            oldData
        );
    };

    const _showAddNewTaskModal = () => {
        _showEditTaskDetailModal(
            {
                id: 0,
                uuid: 'uuid-dummy',
                list_id:
                    taskMenuFilter && taskMenuFilter !== '-1'
                        ? parseInt(taskMenuFilter.replace('tab-tasklist-', ''))
                        : taskListMenu?.[0]?.id,
                d_created: 0,
                d_completed: 0,
                d_edited: 0,
                compl: 0,
                title: '',
                note: '',
                prio: 0,
                ow: 0,
                tags: '',
                tags_ids: '',
                defaulttask_id: 0,
                task_list: {
                    name: taskListMenu?.[0]?.name,
                    contract_id: contractID,
                },
            },
            'ADD'
        );
    };

    const _showEditTaskDetailModal = (
        record: TransactionTaskItemType,
        mode: 'EDIT' | 'ADD' = 'EDIT'
    ) => {
        const editTaskDetailModal = Modal.confirm({
            title: (
                <Typography.Title level={4}>
                    {mode === 'ADD' ? 'Add New Task' : 'Edit Task Detail'}
                </Typography.Title>
            ),
            content: (
                <EditTaskModalContent
                    contractAddress={record.task_list?.contract?.address?.trim()}
                    contractID={isMainMenuTaskTab ? record.task_list?.contract_id : contractID}
                    task={record}
                    form={form}
                    taskListMenu={taskListMenu}
                    mode={mode}
                    subUsers={teamMembers}
                />
            ),
            okText: 'Save',
            icon: <></>,
            style: { top: '40px' },
            width: '750px',
            onOk: async () => {
                editTaskDetailModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Saving...',
                });
                return form
                    .validateFields()
                    .then(async values => {
                        const formValues = {
                            ...values,
                            duedate: values.duedate
                                ? moment(values.duedate).format('YYYY-MM-DD')
                                : null,
                        };

                        if (mode === 'ADD')
                            return _saveNewTaskData(formValues)
                                .then(() => {
                                    editTaskDetailModal.destroy();
                                    return record;
                                })
                                .catch(err => {
                                    throw err;
                                });
                        return _saveTaskData({ ...record, ...formValues }, record, 'FULL')
                            .then(() => {
                                editTaskDetailModal.destroy();
                                return record;
                            })
                            .catch(err => {
                                throw err;
                            });
                    })
                    .catch(err => {
                        console.log('🚀 ~ file: tasks.tsx ~ line 487 ~ onOk: ~ err', err);
                        editTaskDetailModal.update({
                            okButtonProps: {
                                disabled: false,
                            },
                            okText: 'Save',
                        });

                        throw err;
                    });
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _showAddNewTasklistModal = (
        record?: TransactionTaskListType,
        mode: 'EDIT' | 'ADD' = 'EDIT'
    ) => {
        form.setFieldValue('tasklist_name', record && record.name ? record.name : '');
        form.setFieldValue('defaulttasktab_id', undefined);

        const newTasklistModal = Modal.confirm({
            title: (
                <Typography.Title level={4}>
                    {mode === 'ADD' ? 'Add New Tasklist' : 'Edit Tasklist Name'}
                </Typography.Title>
            ),
            content: (
                <>
                    <AddTasklistModalContent form={form} mode={mode} />
                </>
            ),
            okText: mode === 'EDIT' ? 'Save' : 'Add',
            icon: <></>,
            style: { top: '40px' },
            width: '450px',
            onOk: async () => {
                newTasklistModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Saving...',
                });
                return form
                    .validateFields()
                    .then(async values => {
                        const tasklistName = form.getFieldValue('tasklist_name');
                        const defaulttasktab_id = form.getFieldValue('defaulttasktab_id');

                        if (mode === 'ADD') {
                            return addNewTasklist(contractID, {
                                name: tasklistName,
                                defaulttasktab_id: defaulttasktab_id,
                            })
                                .then(resp => {
                                    const taskListRecord = resp.data as TransactionTaskListType;
                                    newTasklistModal.destroy();

                                    _fetchTaskListMenu();
                                    setTaskMenuFilter(`tab-tasklist-${taskListRecord.id}`);
                                    return record;
                                })
                                .catch(err => {
                                    throw err;
                                });
                        } else
                            return updateTasklist(contractID, record?.id || -1, {
                                name: tasklistName,
                            })
                                .then(() => {
                                    newTasklistModal.destroy();

                                    _fetchTaskListMenu();
                                    return record;
                                })
                                .catch(err => {
                                    throw err;
                                });
                    })
                    .catch(err => {
                        console.log('🚀 ~ file: tasks.tsx:662 ~ onOk: ~ err', err);
                        newTasklistModal.update({
                            okButtonProps: {
                                disabled: false,
                            },
                            okText: mode === 'EDIT' ? 'Save' : 'Add',
                        });

                        throw err;
                    });
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _showDeleteTasklistConfirmationModal = (record: TransactionTaskListType) => {
        if (taskListMenu.length <= 1) {
            showMessage('error', `You can't delete the last tasklist.`);
            return;
        }

        form.setFieldValue('tasklist_name', record && record.name ? record.name : '');

        const deletionConfirmationModal = Modal.confirm({
            title: <Typography.Title level={4}>Deletion Confirmation</Typography.Title>,
            content: (
                <Row>
                    <Col span={24}>
                        You are about to delete a tasklist and all its linked dependencies (Task,
                        Reminder, Task's note, and Task Calendar) and you will not be able to
                        restore them once done.
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24}>
                        Are you sure to delete the tasklist: <b>{record.name}</b>?
                    </Col>
                </Row>
            ),
            okText: 'Delete',
            style: { top: '40px' },
            width: '550px',
            onOk: async () => {
                deletionConfirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Deleting...',
                });

                return deleteTasklist(contractID, record.id)
                    .then(() => {
                        deletionConfirmationModal.destroy();
                        showNotification(
                            'success',
                            `The tasklist: ${record.name} has been deleted successfully.`
                        );

                        _fetchTaskListMenu();
                        setTaskMenuFilter('-1');
                        return record;
                    })
                    .catch(err => {
                        throw err;
                    });
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _doActionTaskMenu = (menuKey: string, record: TransactionTaskItemType) => {
        switch (menuKey) {
            case 'edit_task_detail':
                form.resetFields();

                _showEditTaskDetailModal(record);

                break;

            case 'delete_task':
                const deleteConfirmationModal = Modal.confirm({
                    title: `Delete confirmation`,
                    content: (
                        <>
                            Are you sure to delete task: <b>{record.title}</b>?
                        </>
                    ),
                    onOk: () => {
                        deleteConfirmationModal.update({
                            okButtonProps: {
                                disabled: true,
                            },
                            okText: 'Deleting...',
                        });
                        _deleteTask(record).finally(() => {
                            deleteConfirmationModal.destroy();
                        });
                        return true;
                    },
                    closable: true,
                    maskClosable: true,
                });
                break;
            default:
                break;
        }
    };

    const _openTaskEmailModal = (record: TransactionTaskItemType) => {
        const taskEmailModal = Modal.info({
            title: <h3>{`Email Activity for Task: ${record.title}`}</h3>,
            icon: <></>,
            width: '80%',
            style: {
                top: '48px',
            },
            content: <TaskEmailModalContent taskRecord={record} />,
            closable: true,
            maskClosable: true,
            okText: 'Close',
            okType: 'default',
            okButtonProps: {
                style: {
                    display: 'none',
                },
            },
        });
    };

    const _doBulkAction = (action: BULK_ACTIONS, recordKeys: React.Key[], data?: any) => {
        switch (action) {
            case 'MOVE_TO_TASKLIST':
                break;
            case 'SET_DUE_DATE':
                break;
            case 'DELETE':
                break;

            default:
                break;
        }
    };

    const ExpandedRowComponent: React.FC<{ record: TransactionTaskItemType }> = ({ record }) => {
        const [note, setNote] = useState<string>(record.note?.trim() || '');

        const [editMode, setEditMode] = useState<boolean>(false);
        const [docsEditMode, setDocsEditMode] = useState<boolean>(false);
        const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);

        const [docList, setDocList] = useState<TransactionDocItemType[]>([]);
        const [selectedDocList, setSelectedDocList] = useState<TransactionDocItemType[]>([]);

        const _saveNote = () => {
            setIsSubmittingForm(true);
            _saveTaskData({ ...record, note: note }, record).finally(() => {
                setIsSubmittingForm(false);
            });
        };

        const _saveSelectedDocs = () => {
            _linkTaskToDocs(selectedDocList, record)
                .then(result => {})
                .finally(() => {
                    setDocsEditMode(false);
                });
        };

        const _fetchDocList = () => {
            getTransactionDocs(
                contractID,
                qs.stringify({
                    pageSize: 500,
                })
            ).then(result => {
                setDocList(result?.data || []);
            });
        };

        const _onChangeSelectedDocIDs = (selectedDocIDs: any[], selectedDocList: any[]) => {
            setSelectedDocList(selectedDocList);
        };

        useEffect(() => {
            if (docsEditMode) {
                _fetchDocList();
                setSelectedDocList(record.docs || []);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [docsEditMode]);
        return (
            <div className="doc-note-wrapper p-2">
                <div style={{ paddingLeft: '65px' }}>
                    <Row>
                        <Col span={12} className="pr-5">
                            <Row>
                                <Col span={24} className="">
                                    <Row className="">
                                        <Col span={12} className="">
                                            <Text strong>Notes</Text>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {!editMode && UserPermission?.tasks_toggle === 2 && (
                                                <Button
                                                    className="no-print"
                                                    size="small"
                                                    type="link"
                                                    disabled={isTransactionDeleted}
                                                    icon={<EditOutlined />}
                                                    onClick={() => setEditMode(true)}
                                                >
                                                    Edit
                                                </Button>
                                            )}

                                            {editMode && (
                                                <Space>
                                                    <Button
                                                        className="mr-2"
                                                        type="default"
                                                        size="small"
                                                        onClick={() => setEditMode(false)}
                                                        disabled={isSubmittingForm}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className=""
                                                        size="small"
                                                        icon={<SaveOutlined />}
                                                        type="primary"
                                                        loading={isSubmittingForm}
                                                        onClick={() => _saveNote()}
                                                    >
                                                        {isSubmittingForm ? 'Saving' : 'Save'}
                                                    </Button>
                                                </Space>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider className="mt-2 mb-3" />
                                <Col span={24}>
                                    {!editMode && (
                                        <Paragraph
                                            style={{ whiteSpace: 'pre-wrap' }}
                                            ellipsis={{
                                                rows: 5,
                                                expandable: true,
                                            }}
                                        >
                                            {note ? (
                                                note
                                            ) : (
                                                <Text italic type="secondary">
                                                    No note added
                                                </Text>
                                            )}
                                        </Paragraph>
                                    )}
                                    {editMode && (
                                        <div>
                                            <Input.TextArea
                                                style={{ width: '100%' }}
                                                rows={4}
                                                placeholder="Write down the note here..."
                                                className="mb-2"
                                                value={note}
                                                onChange={e => setNote(e.currentTarget.value)}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} className="pl-5">
                            <Row className="">
                                <Col span={24} className="">
                                    <Row className="">
                                        <Col span={12} className="">
                                            <Text strong>Linked Document and Files</Text>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {!docsEditMode &&
                                                UserPermission?.tasks_toggle === 2 && (
                                                    <>
                                                        <Button
                                                            className="no-print"
                                                            size="small"
                                                            type="link"
                                                            disabled={isTransactionDeleted}
                                                            icon={<EditOutlined />}
                                                            onClick={() => setDocsEditMode(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </>
                                                )}

                                            {docsEditMode && (
                                                <Space>
                                                    <Button
                                                        className="mr-2"
                                                        type="default"
                                                        size="small"
                                                        onClick={() => setDocsEditMode(false)}
                                                        disabled={isSubmittingForm}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className=""
                                                        size="small"
                                                        icon={<SaveOutlined />}
                                                        type="primary"
                                                        loading={isSubmittingForm}
                                                        disabled={isSubmittingForm}
                                                        onClick={() => _saveSelectedDocs()}
                                                    >
                                                        {isSubmittingForm ? 'Saving' : 'Save'}
                                                    </Button>
                                                </Space>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider className="mt-2 mb-3" />
                                <Col span={24}>
                                    {!docsEditMode && (
                                        <>
                                            {record.docs?.length ? (
                                                <>
                                                    {record.docs.map(item => (
                                                        <div
                                                            key={`task_docs_${item.doc_id}`}
                                                            className="mb-2"
                                                        >
                                                            <span>
                                                                <span>
                                                                    <Button
                                                                        className=" mr-2"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            previewDoc(
                                                                                'contract_document',
                                                                                [
                                                                                    {
                                                                                        file_id:
                                                                                            item.doc_id,
                                                                                        contract_id:
                                                                                            contractID,
                                                                                    },
                                                                                ]
                                                                            );
                                                                        }}
                                                                        title="Preview the document"
                                                                        icon={
                                                                            <FileSearchOutlined />
                                                                        }
                                                                    ></Button>
                                                                    <u>{item.filename}</u>
                                                                </span>
                                                                {/* &nbsp;(Uploaded by&nbsp;
                                                {item.account?.fullname} on&nbsp;
                                                <EZDateFormat
                                                    value={item.date_uploaded}
                                                    format="MM/DD/YYYY"
                                                />
                                                ) */}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <Text italic type="secondary">
                                                    No linked documents.{' '}
                                                    <Tooltip
                                                        title={`Please visit the "Document
                                                    and Files" tab to upload and then link the docs
                                                    to this task.`}
                                                    >
                                                        <InfoCircleFilled />
                                                    </Tooltip>
                                                </Text>
                                            )}
                                        </>
                                    )}
                                    {docsEditMode && (
                                        <>
                                            <EZSelectDocument
                                                options={docList as any[]}
                                                onChange={(values: any, options: any) =>
                                                    _onChangeSelectedDocIDs(values, options)
                                                }
                                                notFoundContent={
                                                    <span>
                                                        No documents/files uploaded on{' '}
                                                        <b>"Documents and Files"</b> tab.
                                                    </span>
                                                }
                                                value={selectedDocList.map(d => d.doc_id)}
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };

    const _columns: ProColumns<TransactionTaskItemType>[] = [
        {
            title: '',
            dataIndex: 'compl',
            width: isMainMenuTaskTab ? '20px' : '20px',
            align: 'center',
            sorter: true,
            editable: false,
            render: (dom, record) => (
                <Text
                    disabled={isTransactionDeleted}
                    type={record.compl === 1 ? 'secondary' : undefined}
                    className={`cursor-pointer task-completion-toggle ${
                        record.compl === 1 ? 'task-completed' : 'task-not-completed'
                    }`}
                    onClick={e => {
                        const currentTarget = e?.currentTarget;

                        if (
                            (UserPermission && UserPermission?.tasks_toggle !== 2) ||
                            isTransactionDeleted
                        ) {
                            return;
                        }

                        if (currentTarget) {
                            currentTarget.classList.add('loading');
                        }
                        _saveTaskData({ ...record, compl: record.compl === 1 ? 0 : 1 }, record);
                    }}
                    title={`${
                        record.compl === 1 ? 'Mark task as incomplete' : 'Mark task as complete'
                    }`}
                >
                    {record.compl === 1 ? <CheckCircleFilled /> : <CheckCircleOutlined />}
                </Text>
            ),
        },
        {
            title: 'Task Title',
            dataIndex: 'title',
            width: isMainMenuTaskTab ? '180px' : '200px',
            sorter: true,
            renderFormItem(schema: any, { record: newData }, form, action) {
                const oldData = schema?.entity;
                return (
                    <Input
                        onPressEnter={element => {
                            const newTaskTitle = element.currentTarget.value;
                            if (!newTaskTitle || !newData?.id) {
                                return;
                            }
                            newData.title = newTaskTitle;

                            _onSaveTask({ data: newData, oldData });

                            EZTableRef.current?.modifyData(newData, newData.id);
                            action?.cancelEditable(newData.id);
                        }}
                        suffix={
                            <Tooltip title='Press "Enter" to Save'>
                                <EnterOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                        }
                    />
                );
            },
            // ellipsis: true,
            render: (_, record) => (
                <>
                    <Text type={record.compl === 1 ? 'secondary' : undefined} title={record.title}>
                        {record.title || '-'}
                    </Text>
                    {!taskMenuFilter || taskMenuFilter === '-1' ? (
                        <Tag
                            className={`ml-1 mr-1  ${record.compl === 1 ? 'color-secondary' : ''}`}
                        >
                            {record.task_list?.name}
                        </Tag>
                    ) : (
                        ''
                    )}
                </>
            ),
        },
        {
            title: 'Quick Info',
            dataIndex: 'status',
            width: isMainMenuTaskTab ? '60px' : '60px',
            sorter: false,
            editable: false,
            className: 'no-print',
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.note?.length ? 1 : 0} note`} placement="left">
                        <Tag
                            className={`tag-rounded ${
                                record.note?.length ? '' : 'color-secondary'
                            }`}
                            onClick={() => _expandSelectedRows([record.id])}
                        >
                            <MessageOutlined /> {record.note?.length ? 1 : 0}
                        </Tag>
                    </Tooltip>
                    <Tooltip
                        title={`${record.docs?.length || 0} linked document(s)`}
                        placement="top"
                    >
                        <Tag
                            className={`tag-rounded  ${
                                record.docs?.length ? '' : 'color-secondary'
                            }`}
                            onClick={() => _expandSelectedRows([record.id])}
                        >
                            <CloudServerOutlined /> {record.docs?.length || 0}
                        </Tag>
                    </Tooltip>
                    <Tooltip
                        title={`${
                            record.email_template_id
                                ? '1 email template is linked. '
                                : 'Click to open email activity.'
                        }`}
                        // title={`1 email template linked, and ${
                        //     record.emails?.length || 0
                        // } email(s) sent`}
                        placement="right"
                    >
                        <Tag
                            className={`tag-rounded mr-0 cursor-pointer ${
                                record.email_template_id ? '' : 'color-secondary'
                            }`}
                            onClick={() => _openTaskEmailModal(record)}
                            color="default"
                        >
                            <MailOutlined />
                            {/* {record.emails_sent_count || 0} */}
                        </Tag>
                    </Tooltip>
                </>
            ),
        },
        {
            title: 'Due Date',
            dataIndex: 'duedate',
            valueType: 'date',
            width: isMainMenuTaskTab ? '60px' : '70px',
            render: (_, record, idx, action) => {
                const currentYear = new Date().getFullYear();
                const nowMoment = moment();

                const dueDateMoment = record.duedate
                    ? moment(record.duedate)
                          .hour(nowMoment.hour())
                          .minute(nowMoment.minute())
                          .second(nowMoment.second())
                          .millisecond(nowMoment.millisecond())
                    : null;

                const isDue =
                    record.compl !== 1 &&
                    dueDateMoment &&
                    dueDateMoment.isBefore(nowMoment.clone().startOf('day'));
                const isWithin2Weeks = dueDateMoment?.isValid()
                    ? dueDateMoment.isBetween(moment().subtract(7, 'days'), moment().add(7, 'days'))
                    : false;

                const textType = isDue
                    ? 'danger'
                    : dueDateMoment && dueDateMoment.diff(nowMoment, 'day') === 0
                    ? 'success'
                    : dueDateMoment && dueDateMoment.diff(nowMoment, 'day') === 1
                    ? undefined
                    : record.compl === 1
                    ? 'secondary'
                    : undefined;

                const dueDateFormat =
                    dueDateMoment && dueDateMoment.year() === currentYear
                        ? 'MMM Do'
                        : 'MMM Do, YYYY';

                const customText =
                    isWithin2Weeks && dueDateMoment
                        ? dueDateMoment.diff(nowMoment, 'day') === 0
                            ? 'Today'
                            : dueDateMoment.diff(nowMoment, 'day') === 1
                            ? 'Tomorrow'
                            : dueDateMoment.diff(nowMoment, 'day') === -1
                            ? 'Yesterday'
                            : ''
                        : '';

                return (
                    <Text
                        type={textType}
                        strong={
                            ['Yesterday', 'Today', 'Tomorrow'].includes(customText) ? true : false
                        }
                    >
                        {isWithin2Weeks ? (
                            <Tooltip title={`${dueDateMoment?.format('MMM Do, YYYY')}`}>
                                <span>
                                    {customText ? (
                                        customText
                                    ) : (
                                        <EZDateFormat value={record.duedate} humanize />
                                    )}
                                </span>
                            </Tooltip>
                        ) : (
                            <EZDateFormat
                                noTimezone
                                value={record.duedate}
                                format={dueDateFormat}
                            />
                        )}
                    </Text>
                );
            },
            fieldProps: {
                format: 'MMM Do, YYYY',
            },
            sorter: true,
        },
        {
            title: 'Assignee',
            className: 'task-assignee-column',
            dataIndex: 'assignee',
            width: '70px',
            valueType: 'select',
            sorter: false,
            ellipsis: true,
            fieldProps: {
                options: teamMembers?.map(user => ({
                    label: user.fullname,
                    value: user.account_id,
                })),
                showSearch: true,
            },
            render: (_, record) => {
                const memberDetail = teamMembers.find(
                    ({ account_id }) => account_id === record.assignee
                );
                return <div>{memberDetail?.fullname || '-'}</div>;
            },
        },
        {
            title: 'Priority',
            dataIndex: 'prio',
            width: isMainMenuTaskTab ? '45px' : '45px',
            valueType: 'select',
            sorter: true,
            fieldProps: {
                options: [
                    {
                        label: <Badge count={'-1'} style={{ backgroundColor: '#2db7f5' }} />,
                        value: -1,
                    },
                    {
                        label: <Badge count={'±0'} style={{ backgroundColor: 'grey' }} />,
                        value: 0,
                    },
                    {
                        label: <Badge count={'+1'} style={{ backgroundColor: 'gold' }} />,
                        value: 1,
                    },
                    {
                        label: <Badge count={'+2'} style={{ backgroundColor: '#f50' }} />,
                        value: 2,
                    },
                ],
            },
            render: (_, record, idx, action) => <div>{_renderPriorityBadge(record)}</div>,
        },
        {
            title: 'Last Edited On',
            dataIndex: 'd_edited',
            width: isMainMenuTaskTab ? '100px' : '70px',
            sorter: true,
            editable: false,
            className: 'no-print',
            render: (_, record) => (
                <>
                    <Text type={record.compl === 1 ? 'secondary' : undefined}>
                        <EZDateFormat value={record.d_edited} format={'MMM DD, YYYY hh:mma'} />
                    </Text>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: isMainMenuTaskTab ? '75px' : '60px',
            valueType: 'option',
            className: 'no-print',
            hideInTable: UserPermission && UserPermission?.tasks_toggle === 2 ? false : true,
            render: (_, record, index, action) =>
                isTransactionDeleted ? (
                    <></>
                ) : (
                    <>
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<FieldTimeOutlined />}
                            title="Edit this task"
                            onClick={() => {
                                action?.startEditable(record.id);
                            }}
                        >
                            Quick Edit
                        </Button>

                        <Divider type="vertical" />

                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu
                                    items={[
                                        {
                                            icon: <MenuUnfoldOutlined className="mr-3" />,
                                            label: 'Edit Task Detail',
                                            key: 'edit_task_detail',
                                        },
                                        { type: 'divider' },
                                        {
                                            icon: <DeleteOutlined className="mr-3" />,
                                            label: 'Delete Task',
                                            key: 'delete_task',
                                            danger: true,
                                        },
                                    ]}
                                    onClick={({ key }) => _doActionTaskMenu(key, record)}
                                />
                            }
                            placement="bottomRight"
                        >
                            <span onClick={e => e.preventDefault()}>
                                <Button
                                    className="p-0"
                                    type="link"
                                    icon={<EllipsisOutlined />}
                                    title="More action"
                                ></Button>
                            </span>
                        </Dropdown>
                    </>
                ),
            fixed: 'right',
        },
    ];

    if (isMainMenuTaskTab) {
        let tmp = _columns[2];
        _columns[2] = _columns[3];
        _columns[3] = tmp;
        _columns.splice(3, 0, {
            title: 'Address',
            dataIndex: '$task_list.contract.address$',
            editable: false,
            width: '160px',
            sorter: true,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text
                        onClick={() => _openNewTab(record)}
                        underline
                        className="cursor-pointer"
                        type={record.compl === 1 ? 'secondary' : undefined}
                    >
                        {record.task_list?.contract?.address || '-'},&nbsp;
                        {record.task_list?.contract?.city || '-'},&nbsp;
                        {record.task_list?.contract?.state || '-'}
                    </Text>
                </>
            ),
        });
    } else if (disableDrag === false) {
        _columns.push(
            {
                title: 'Completed On',
                dataIndex: 'd_completed',
                width: isMainMenuTaskTab ? '120px' : '70px',
                sorter: true,
                editable: false,
                className: 'no-print',
                render: (_, record) => (
                    <>
                        <Text type={record.compl === 1 ? 'secondary' : undefined}>
                            <EZDateFormat
                                value={record.compl === 1 ? record.d_completed : null}
                                format={'MMM DD, YYYY hh:mma'}
                            />
                        </Text>
                    </>
                ),
            },
            {
                key: 'sort',
                fixed: 'left',
                align: 'center',
                width: 18,
                render: () => <DragHandle />,
            }
        );
    } else {
        _columns.push({
            title: 'Completed On',
            dataIndex: 'd_completed',
            width: isMainMenuTaskTab ? '120px' : '70px',
            sorter: true,
            editable: false,
            className: 'no-print',
            render: (_, record) => (
                <>
                    <Text type={record.compl === 1 ? 'secondary' : undefined}>
                        <EZDateFormat
                            value={record.compl === 1 ? record.d_completed : null}
                            format={'MMM DD, YYYY hh:mma'}
                        />
                    </Text>
                </>
            ),
        });
    }

    const onEditTaskTabs = (action: 'add' | 'remove') => {
        if (action === 'add') {
            _showAddNewTasklistModal(undefined, 'ADD');
        } else {
        }
    };

    const _fetchSubUsers = () => {
        getAllTeamMembers().then(({ data }) => {
            setTeamMembers((data as AccountCompactDataType[]) || []);
        });
    };

    useEffect(() => {
        _applyFilter(
            filterType,
            taskTypeFilter,
            taskMenuFilter,
            transactionStatusFilter,
            toggleMyTasks
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType, taskTypeFilter, taskMenuFilter, transactionStatusFilter, toggleMyTasks]);

    useEffect(() => {
        if (pageType === TASK_EMBEDDED_TYPE.TRANSACTION_DETAIL) {
            _reloadSubTabData();
            _fetchTaskListMenu();
        }

        _fetchSubUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez-component-tasks">
            {pageType === TASK_EMBEDDED_TYPE.MAIN_MENU && (
                <div className="mb-5 ez-sub-content-wrapper">
                    <Row>
                        <Col span={12} style={{ textAlign: 'left' }}>
                            <Segmented
                                options={[
                                    { label: 'Overdue', value: 'overdue' },
                                    { label: 'Today', value: 'today' },
                                    { label: 'This Week', value: 'this_week' },
                                    { label: 'Next 14 Days', value: 'next_14_days' },
                                    { label: 'This Month', value: 'this_month' },
                                    { label: 'All Tasks', value: 'all_tasks' },
                                ]}
                                value={filterType}
                                onChange={val => setFilterType(val as string)}
                            />
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <EZSelectStatus
                                style={{ textAlign: 'left', width: 180 }}
                                showAllActiveStatusesOpt
                                maxTagCount={'responsive'}
                                // mode="multiple"
                                allowClear={false}
                                optionFilterProp="label"
                                className="mr-3"
                                placeholder={
                                    <span>
                                        <Text>Transaction Status: </Text>
                                        <Text strong>All Statuses</Text>
                                    </span>
                                }
                                defaultValue={{
                                    label: 'All Active Statuses',
                                    value: 'all_active_statuses',
                                }}
                                onChange={val => setTransactionStatusFilter(val.toString())}
                            />
                            <Select
                                style={{ textAlign: 'left', width: 300 }}
                                value={taskTypeFilter}
                                onChange={val => setTaskTypeFilter(val)}
                            >
                                <Option value="incomplete_tasks">
                                    <span>
                                        <Text>Show: </Text>
                                        <Text strong>Incomplete Tasks</Text>
                                    </span>
                                </Option>
                                <Option value="completed_tasks">
                                    <span>
                                        <Text>Show: </Text>
                                        <Text strong>Completed Tasks</Text>
                                    </span>
                                </Option>
                                <Option value="all_tasks">
                                    <span>
                                        <Text>Show: </Text>
                                        <Text strong>All Tasks</Text>
                                    </span>
                                </Option>
                            </Select>
                        </Col>
                    </Row>
                </div>
            )}
            <Row gutter={[0, 0]} className="row-wrapper">
                <>
                    {/* <UploadProgress
                        fileUploadList={fileUploadList}
                        setFileUploadList={setFileUploadList}
                    /> */}
                </>
                <Col span={24}>
                    {pageType === TASK_EMBEDDED_TYPE.TRANSACTION_DETAIL && (
                        <Tabs
                            type={isTransactionDeleted ? 'card' : 'editable-card'}
                            defaultActiveKey="-1"
                            addIcon={
                                <Tooltip title="Add new task list">
                                    <PlusOutlined />
                                </Tooltip>
                            }
                            onChange={val => {
                                setTaskMenuFilter(val);
                            }}
                            // onEdit={onEditTaskTabs}
                            onEdit={(e: any, action) => onEditTaskTabs(action)}
                            activeKey={taskMenuFilter}
                            tabBarExtraContent={
                                <div>
                                    <Select
                                        style={{ textAlign: 'left', width: 300 }}
                                        value={taskTypeFilter}
                                        onChange={val => setTaskTypeFilter(val)}
                                        className="mb-2 ml-3"
                                    >
                                        <Option value="incomplete_tasks">
                                            <span>
                                                <Text>Show: </Text>
                                                <Text strong>Incomplete Tasks</Text>
                                            </span>
                                        </Option>
                                        <Option value="completed_tasks">
                                            <span>
                                                <Text>Show: </Text>
                                                <Text strong>Completed Tasks</Text>
                                            </span>
                                        </Option>
                                        <Option value="all_tasks">
                                            <span>
                                                <Text>Show: </Text>
                                                <Text strong>All Tasks</Text>
                                            </span>
                                        </Option>
                                    </Select>
                                </div>
                            }
                        >
                            <Tabs.TabPane tab="All tasks" key="-1" closable={false}></Tabs.TabPane>
                            {taskListMenu.map(taskListItem => (
                                <Tabs.TabPane
                                    tab={
                                        <span>
                                            {taskListItem.name}
                                            {taskMenuFilter === `tab-tasklist-${taskListItem.id}` &&
                                                !isTransactionDeleted && (
                                                    <Dropdown
                                                        overlay={
                                                            <Menu
                                                                items={[
                                                                    {
                                                                        label: 'Rename',
                                                                        key: 'rename_tasklist',
                                                                        onClick: () =>
                                                                            _showAddNewTasklistModal(
                                                                                taskListItem,
                                                                                'EDIT'
                                                                            ),
                                                                    },
                                                                    { type: 'divider' },
                                                                    {
                                                                        label: 'Delete Tasklist',
                                                                        key: 'delete_tasklist',
                                                                        danger: true,
                                                                        onClick: () =>
                                                                            _showDeleteTasklistConfirmationModal(
                                                                                taskListItem
                                                                            ),
                                                                    },
                                                                ]}
                                                            />
                                                        }
                                                        trigger={['click']}
                                                    >
                                                        {/* <a onClick={e => e.preventDefault()}> */}
                                                        <Space>
                                                            <CaretDownOutlined className="ml-4 mr-0" />
                                                        </Space>
                                                        {/* </a> */}
                                                    </Dropdown>
                                                )}
                                        </span>
                                    }
                                    key={`tab-tasklist-${taskListItem.id}`}
                                    closable={false}
                                ></Tabs.TabPane>
                            ))}
                        </Tabs>
                    )}
                </Col>
                <Col span={24} ref={componentRef}>
                    {/* {selectedRowKeys?.length ? (
                        <Row className="table-selected-indicator" style={{ top: '0px' }}>
                            <Col span={24}>
                                <Alert
                                    className={`${
                                        selectedRowKeys?.length
                                            ? 'has-row-selected'
                                            : 'no-row-selected'
                                    }`}
                                    message={
                                        selectedRowKeys && selectedRowKeys.length ? (
                                            <Space>
                                                <Tooltip title="Move the selected task(s)">
                                                    <Dropdown
                                                        trigger={['click']}
                                                        overlay={
                                                            <Menu
                                                                onClick={({ key }) =>
                                                                    _doBulkAction(
                                                                        'MOVE_TO_TASKLIST',
                                                                        selectedRowKeys,
                                                                        { selectedTaskListID: key }
                                                                    )
                                                                }
                                                                items={taskListMenu.map(item => ({
                                                                    label: item.name,
                                                                    key: item.id,
                                                                }))}
                                                            />
                                                        }
                                                        placement="bottomLeft"
                                                    >
                                                        <Button
                                                            icon={<DeliveredProcedureOutlined />}
                                                        >
                                                            Move To
                                                        </Button>
                                                    </Dropdown>
                                                </Tooltip>
                                                <Divider type="vertical" />
                                                <Tooltip title="Set Due Date">
                                                    <Button
                                                        icon={<CalendarFilled />}
                                                        onClick={() =>
                                                            _doBulkAction(
                                                                'SET_DUE_DATE',
                                                                selectedRowKeys
                                                            )
                                                        }
                                                    >
                                                        Set Due Date
                                                    </Button>
                                                </Tooltip>
                                                <Divider type="vertical" />
                                                <Tooltip title="Remove the selected task(s)">
                                                    <Popconfirm
                                                        arrowPointAtCenter
                                                        title="Are you sure to delete the selected task(s)?"
                                                        okText="Yes"
                                                        cancelText="No"
                                                        placement="bottomLeft"
                                                    >
                                                        <Button
                                                            danger
                                                            icon={<DeleteFilled />}
                                                        ></Button>
                                                    </Popconfirm>
                                                </Tooltip>
                                            </Space>
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )} */}
                    <div className="only-print text-center mb-0 mt-0">
                        <b>Tasklist of {contractAddress}</b>
                    </div>

                    <div className="pr-3 pl-3 text-help mb-3" style={{ textAlign: 'left' }}>
                        <InfoCircleOutlined /> Tip: Scroll horizontally over the below table to see
                        other information. Click{' '}
                        <Popover
                            content={
                                <Row gutter={[0, 12]} style={{ width: '500px' }}>
                                    <Col>
                                        <b>Tip #1</b> - Mark a task as completed by hitting the{' '}
                                        <CheckCircleOutlined style={{ fontSize: '16px' }} /> icon.
                                    </Col>
                                    <Col>
                                        <b>Tip #2</b> - Click the <RightOutlined /> icon to show the
                                        task's notes and/or edit the linked documents.
                                    </Col>
                                    {isMainMenuTaskTab && (
                                        <Col>
                                            <b>Tip #3</b> - Click the task's <u>Address</u> to
                                            immediately open the corresponding transaction/contract
                                            detail in a new EZ tab.
                                        </Col>
                                    )}
                                </Row>
                            }
                            title={
                                <>
                                    {isMainMenuTaskTab ? (
                                        <>
                                            More tips on the <b>Tasks</b> page.
                                        </>
                                    ) : (
                                        <>
                                            More tips on the <b>Tasks</b> tab.
                                        </>
                                    )}
                                </>
                            }
                            trigger="click"
                            // placement="right"
                        >
                            <a>here</a>
                        </Popover>{' '}
                        to see more tips.
                    </div>
                    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext
                            items={transactionTasks.map(i => i.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            {isMainMenuTaskTab ? (
                                <EZTable
                                    className="eztable-hide-delete-editable"
                                    ref={EZTableRef}
                                    pagination={{
                                        pageSize:
                                            pageType === TASK_EMBEDDED_TYPE.TRANSACTION_LIST
                                                ? 10
                                                : 100,
                                        pageSizeOptions:
                                            pageType === TASK_EMBEDDED_TYPE.TRANSACTION_LIST
                                                ? [10]
                                                : [20, 50, 100],
                                        position:
                                            pageType === TASK_EMBEDDED_TYPE.TRANSACTION_LIST
                                                ? ['bottomLeft']
                                                : ['bottomLeft', 'topLeft'],
                                    }}
                                    components={{ body: { row: RowTable } }}
                                    queryParams={queryParams}
                                    // rowSelection={{
                                    //     selectedRowKeys: selectedRowKeys,
                                    //     onChange: (newSelectedRowKeys: React.Key[]) => {
                                    //         setSelectedRowKeys(newSelectedRowKeys);
                                    //     },
                                    //     alwaysShowAlert: true,
                                    //     columnWidth: '16px',
                                    // }}
                                    // tableAlertRender={false}
                                    expandable={{
                                        columnWidth: '16px',
                                        expandedRowRender: (record: TransactionTaskItemType) => {
                                            return <ExpandedRowComponent record={record} />;
                                        },
                                        expandIcon: _expandIcon,
                                        showExpandColumn: true,
                                        expandedRowKeys: expandedRecordIDs,
                                        onExpand: (expanded, record: TransactionTaskItemType) => {
                                            if (expanded) {
                                                setExpandedRecordIDs([
                                                    ...expandedRecordIDs,
                                                    record.id,
                                                ]);
                                            } else {
                                                setExpandedRecordIDs([
                                                    ...expandedRecordIDs.filter(
                                                        id => id !== record.id
                                                    ),
                                                ]);
                                            }
                                        },
                                    }}
                                    columns={_columns}
                                    size="small"
                                    rowKey="id"
                                    fetchData={_fetchData}
                                    scroll={{ x: isMainMenuTaskTab ? 2200 : 2000 }}
                                    toolBarComponents={[
                                        <Switch
                                            className="ml-2 switch-table-component"
                                            checkedChildren={
                                                <>
                                                    <EyeInvisibleOutlined className="mr-2" />
                                                    My tasks
                                                </>
                                            }
                                            unCheckedChildren={
                                                <>
                                                    <EyeOutlined className="mr-2" />
                                                    My tasks
                                                </>
                                            }
                                            size="default"
                                            style={{ width: '110px' }}
                                            checked={toggleMyTasks}
                                            onChange={val => setToggleMyTasks(val)}
                                        />,
                                        <Button
                                            className="ml-2 ez-action-btn"
                                            icon={<PrinterOutlined />}
                                            onClick={handlePrint}
                                        />,
                                        <Button
                                            key={`toolBarComponents-1-task-list`}
                                            className="ml-2 ez-action-btn"
                                            icon={
                                                toggleAllRows ? <RightOutlined /> : <DownOutlined />
                                            }
                                            onClick={() => _toggleAllRows(!toggleAllRows)}
                                        >
                                            {toggleAllRows ? 'Collapse' : 'Expand'} all rows
                                        </Button>,
                                        pageType === TASK_EMBEDDED_TYPE.TRANSACTION_DETAIL &&
                                            !isTransactionDeleted && [
                                                UserPermission &&
                                                UserPermission?.tasks_toggle === 2 ? (
                                                    <Button
                                                        key={`toolBarComponents-2-task-list`}
                                                        className="ml-2 mr-2 ez-action-btn"
                                                        onClick={() => _showAddNewTaskModal()}
                                                    >
                                                        <PlusOutlined />
                                                        Add New Task
                                                    </Button>
                                                ) : (
                                                    <></>
                                                ),
                                            ],
                                    ]}
                                    editable={{
                                        actionRender: (row, config, defaultDom) => [
                                            defaultDom.save,
                                            defaultDom.cancel,
                                            // defaultDom.delete,
                                        ],
                                        type: 'multiple',
                                        onSave: async (_, data: TransactionTaskItemType, oldData) =>
                                            _onSaveTask({ data, oldData }),
                                        saveText: (
                                            <>
                                                <Button
                                                    className="p-0 pl-1"
                                                    type="link"
                                                    icon={<SaveFilled />}
                                                    title="Edit this task"
                                                >
                                                    Save
                                                </Button>
                                            </>
                                        ),
                                        deletePopconfirmMessage:
                                            'Are you sure to delete this task?',
                                        deleteText: (
                                            <>
                                                <Divider type="vertical" />
                                                <Button
                                                    className="p-0"
                                                    type="text"
                                                    title="Delete"
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                ></Button>
                                            </>
                                        ),
                                        onDelete: (key, record) => _deleteTask(record),
                                        cancelText: (
                                            <>
                                                <Divider type="vertical" />
                                                <Button
                                                    className="p-0 text-grey mr-2"
                                                    type="text"
                                                    title="Cancel and back"
                                                    icon={<CloseOutlined />}
                                                ></Button>
                                            </>
                                        ),
                                    }}
                                    onChangeCallback={_onChangeCallback}
                                />
                            ) : (
                                <EZTable
                                    className="eztable-hide-delete-editable"
                                    ref={EZTableRef}
                                    dataSource={transactionTasks}
                                    pagination={{
                                        pageSize:
                                            pageType === TASK_EMBEDDED_TYPE.TRANSACTION_LIST
                                                ? 10
                                                : 100,
                                        pageSizeOptions:
                                            pageType === TASK_EMBEDDED_TYPE.TRANSACTION_LIST
                                                ? [10]
                                                : [20, 50, 100],
                                        position:
                                            pageType === TASK_EMBEDDED_TYPE.TRANSACTION_LIST
                                                ? ['bottomLeft']
                                                : ['bottomLeft', 'topLeft'],
                                    }}
                                    components={{ body: { row: RowTable } }}
                                    queryParams={queryParams}
                                    // rowSelection={{
                                    //     selectedRowKeys: selectedRowKeys,
                                    //     onChange: (newSelectedRowKeys: React.Key[]) => {
                                    //         setSelectedRowKeys(newSelectedRowKeys);
                                    //     },
                                    //     alwaysShowAlert: true,
                                    //     columnWidth: '16px',
                                    // }}
                                    // tableAlertRender={false}
                                    expandable={{
                                        columnWidth: '16px',
                                        expandedRowRender: (record: TransactionTaskItemType) => {
                                            return <ExpandedRowComponent record={record} />;
                                        },
                                        expandIcon: _expandIcon,
                                        showExpandColumn: true,
                                        expandedRowKeys: expandedRecordIDs,
                                        onExpand: (expanded, record: TransactionTaskItemType) => {
                                            if (expanded) {
                                                setExpandedRecordIDs([
                                                    ...expandedRecordIDs,
                                                    record.id,
                                                ]);
                                            } else {
                                                setExpandedRecordIDs([
                                                    ...expandedRecordIDs.filter(
                                                        id => id !== record.id
                                                    ),
                                                ]);
                                            }
                                        },
                                    }}
                                    columns={_columns}
                                    size="small"
                                    rowKey="id"
                                    fetchData={_fetchData}
                                    scroll={{ x: isMainMenuTaskTab ? 2200 : 2000 }}
                                    toolBarComponents={[
                                        <Switch
                                            className="ml-2 switch-table-component"
                                            checkedChildren={
                                                <>
                                                    <EyeInvisibleOutlined className="mr-2" />
                                                    My tasks
                                                </>
                                            }
                                            unCheckedChildren={
                                                <>
                                                    <EyeOutlined className="mr-2" />
                                                    My tasks
                                                </>
                                            }
                                            size="default"
                                            style={{ width: '110px' }}
                                            checked={toggleMyTasks}
                                            onChange={val => setToggleMyTasks(val)}
                                        />,
                                        <Button
                                            className="ml-2 ez-action-btn"
                                            icon={<PrinterOutlined />}
                                            onClick={handlePrint}
                                        />,
                                        <Button
                                            key={`toolBarComponents-1-task-list`}
                                            className="ml-2 ez-action-btn"
                                            icon={
                                                toggleAllRows ? <RightOutlined /> : <DownOutlined />
                                            }
                                            onClick={() => _toggleAllRows(!toggleAllRows)}
                                        >
                                            {toggleAllRows ? 'Collapse' : 'Expand'} all rows
                                        </Button>,
                                        pageType === TASK_EMBEDDED_TYPE.TRANSACTION_DETAIL &&
                                            !isTransactionDeleted && [
                                                UserPermission &&
                                                UserPermission?.tasks_toggle === 2 ? (
                                                    <Button
                                                        key={`toolBarComponents-2-task-list`}
                                                        className="ml-2 mr-2 ez-action-btn"
                                                        onClick={() => _showAddNewTaskModal()}
                                                    >
                                                        <PlusOutlined />
                                                        Add New Task
                                                    </Button>
                                                ) : (
                                                    <></>
                                                ),
                                            ],
                                    ]}
                                    editable={{
                                        actionRender: (row, config, defaultDom) => [
                                            defaultDom.save,
                                            defaultDom.cancel,
                                            // defaultDom.delete,
                                        ],
                                        type: 'multiple',
                                        onSave: async (_, data: TransactionTaskItemType, oldData) =>
                                            _onSaveTask({ data, oldData }),
                                        saveText: (
                                            <>
                                                <Button
                                                    className="p-0 pl-1"
                                                    type="link"
                                                    icon={<SaveFilled />}
                                                    title="Edit this task"
                                                >
                                                    Save
                                                </Button>
                                            </>
                                        ),
                                        deletePopconfirmMessage:
                                            'Are you sure to delete this task?',
                                        deleteText: (
                                            <>
                                                <Divider type="vertical" />
                                                <Button
                                                    className="p-0"
                                                    type="text"
                                                    title="Delete"
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                ></Button>
                                            </>
                                        ),
                                        onDelete: (key, record) => _deleteTask(record),
                                        cancelText: (
                                            <>
                                                <Divider type="vertical" />
                                                <Button
                                                    className="p-0 text-grey mr-2"
                                                    type="text"
                                                    title="Cancel and back"
                                                    icon={<CloseOutlined />}
                                                ></Button>
                                            </>
                                        ),
                                    }}
                                    onChangeCallback={_onChangeCallback}
                                />
                            )}
                        </SortableContext>
                    </DndContext>
                </Col>
            </Row>
        </div>
    );
};
