import React, { useEffect, useState } from 'react';

import { Button, Col, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { DocusignCredentialType } from 'types/docusign';
import {
    processDocusignLogout,
    generateDocuSignAuthURL,
    getDocusignAccountInformation,
} from 'utils/ez_api/docusignAPI';
import { showNotification } from 'utils/notificationUtil';

export const DocusignSetting: React.FC<{}> = ({}) => {
    const [credential, setCredential] = useState<DocusignCredentialType>();

    const _fetchCredentials = async () => {
        getDocusignAccountInformation().then(resp => {
            setCredential(resp.data);
        });
    };

    const _signInDocuSign = () => {
        generateDocuSignAuthURL().then(resp => {
            window.open(resp.data.url, '_blank');
        });
    };

    const _onDocuSignLogout = () => {
        processDocusignLogout().then(resp => {
            showNotification('success', 'You have unlinked a DocuSign account successfully.');
            _fetchCredentials();
        });
    };

    useEffect(() => {
        _fetchCredentials();
    }, []);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data === 'exchange-code-completed') {
                _fetchCredentials();
            }
        };

        window.addEventListener('message', handleMessage);

        // Clean up listener on component unmount
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div className="ez_email_logs">
            <Row gutter={[0, 24]} className="">
                <Col span={24} className="">
                    <span style={{ width: '220px' }} className="mr-2">
                        <Button icon={<EditOutlined />} onClick={() => _signInDocuSign()}>
                            {credential?.docusign_name && credential?.docusign_email ? (
                                <>
                                    Signed in as <b>{credential.docusign_email}</b>
                                </>
                            ) : (
                                <>Sign in with your DocuSign</>
                            )}
                        </Button>
                    </span>
                    {credential?.docusign_name && credential?.docusign_email && (
                        <a className="mr-2" onClick={() => _onDocuSignLogout()}>
                            <u>Logout</u>
                        </a>
                    )}
                </Col>
                <Col span={24} className="mt-4"></Col>
            </Row>
        </div>
    );
};
