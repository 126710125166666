import { Col, Row, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { DocusignSetting } from './integration/docusignSetting';

export const IntegrationSetting: React.FC<{}> = ({}) => {
    const [activeTabKey, setActiveTabKey] = useState('integration-docusign');

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Tabs
                    className="tab-integration-setting"
                    defaultActiveKey="integration-docusign"
                    type="card"
                    activeKey={activeTabKey}
                    onChange={key => setActiveTabKey(key)}
                    destroyInactiveTabPane
                >
                    <Tabs.TabPane tab={`DocuSign`} key={`integration-docusign`}>
                        <DocusignSetting />
                    </Tabs.TabPane>
                </Tabs>
            </Col>
        </Row>
    );
};
