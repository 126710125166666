import { Mixpanel } from 'utils/mixPanelUtil';
import { APIRequest } from '../apiUtil';
import { apiUrl } from '../envUtil';

export const generateDocuSignAuthURL = () => {
    Mixpanel.track('app.generate_docusign_auth_url', null);

    return APIRequest({
        method: 'get',
        url: apiUrl + `admin/docusign/auth-url`,
        useDefaultErrorNotif: true,
    });
};

export const exchangeDocuSignCode = (code: string, state: string) => {
    Mixpanel.track('app.exchange_docusign_code', null);

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/docusign/exchange-code`,
        data: { code, state },
        useDefaultErrorNotif: true,
    });
};

export const getDocusignAccountInformation = () => {
    Mixpanel.track('app.get_docusign_account', null);

    return APIRequest({
        method: 'get',
        url: apiUrl + `admin/docusign/account`,
        useDefaultErrorNotif: true,
    });
};

export const processDocusignLogout = () => {
    Mixpanel.track('app.process_docusign_logout', null);

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/docusign/logout`,
        useDefaultErrorNotif: true,
    });
};
