import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getAlertSetting = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'admin/settings/alerts',
    });
};

export const getPropertyStatusSetting = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'admin/settings/property-status',
        useDefaultErrorNotif: true,
    });
};

export const getPropertyTypeSetting = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'admin/settings/property-type',
        useDefaultErrorNotif: true,
    });
};

export const getSaleTypeSetting = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'admin/settings/sale-type',
        useDefaultErrorNotif: true,
    });
};

export const getTaskTabsDetail = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'admin/settings/default-tasktabs',
        useDefaultErrorNotif: true,
    });
};

export const getContractCustomFields = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'transaction/all-additional-info',
        useDefaultErrorNotif: true,
    });
};

export const updateAlertSetting = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'alerts',
        action: 'edit',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/alerts`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateTransactionSetting = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: payload?.property_status
            ? 'property_status'
            : payload?.property_types
            ? 'property_types'
            : payload?.sale_types
            ? 'sale_types'
            : '-',
        action: 'edit',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/transaction`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateDefaultTaskTabData = (defaulttasktab_id: number, payload: any) => {
    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/default-tasktab/${defaulttasktab_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const bulkUpdateDefaultTaskPositions = (
    defaulttasktab_id: number,
    arrTaskIDsOrder: { defaulttask_id: number; position_order: number }[]
) => {
    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/default-tasktab/${defaulttasktab_id}/positions`,
        data: {
            arrTaskIDsOrder,
        },
        useDefaultErrorNotif: true,
    });
};

export const bulkUpdateCustomFieldPositions = (
    additional_info_fields_category_id: number,
    arrCustomFieldIDsOrder: { additional_info_field_id: number; rank: number }[]
) => {
    return APIRequest({
        method: 'put',
        url:
            apiUrl +
            `admin/settings/contract-custom-section/${additional_info_fields_category_id}/positions`,
        data: {
            arrCustomFieldIDsOrder,
        },
        useDefaultErrorNotif: true,
    });
};

export const updateDefaultTaskData = (
    defaulttasktab_id?: number,
    defaulttask_id?: number,
    payload?: any
) => {
    return APIRequest({
        method: 'put',
        url:
            apiUrl +
            `admin/settings/default-tasktab/${defaulttasktab_id}/default-task/${defaulttask_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const addNewDefaultTaskTab = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'task_templates',
        action: 'add_tasktab',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/default-tasktabs`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const addNewDefaultTaskData = (defaulttasktab_id?: number, payload?: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'task_templates',
        action: 'add_task',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/default-tasktab/${defaulttasktab_id}/default-tasks`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteDefaultTask = (defaulttasktab_id: number, defaulttask_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'task_templates',
        action: 'delete_task',
    });

    return APIRequest({
        method: 'delete',
        url:
            apiUrl +
            `admin/settings/default-tasktab/${defaulttasktab_id}/default-task/${defaulttask_id}`,
        useDefaultErrorNotif: true,
    });
};

export const deleteDefaultTaskTab = (defaulttasktab_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'task_templates',
        action: 'delete_tasktab',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `admin/settings/default-tasktab/${defaulttasktab_id}`,
        useDefaultErrorNotif: true,
    });
};

export const duplicateTaskTemplate = (defaulttasktab_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'task_templates',
        action: 'duplicate_tasktab',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/default-tasktab/${defaulttasktab_id}/duplicate`,
        useDefaultErrorNotif: true,
    });
};
// Custom sections and fields APIs

export const addNewContractCustomSection = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'custom_section_fields',
        action: 'add_section',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/contract-custom-sections`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};
export const updateContractCustomSection = (
    additional_info_fields_category_id: number,
    payload: any
) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'custom_section_fields',
        action: 'edit_section',
    });

    return APIRequest({
        method: 'put',
        url:
            apiUrl + `admin/settings/contract-custom-section/${additional_info_fields_category_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteContractCustomSection = (additional_info_fields_category_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'custom_section_fields',
        action: 'delete_section',
    });

    return APIRequest({
        method: 'delete',
        url:
            apiUrl + `admin/settings/contract-custom-section/${additional_info_fields_category_id}`,
        useDefaultErrorNotif: true,
    });
};

export const addNewContractCustomField = (
    additional_info_fields_category_id: number,
    payload: any
) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'custom_section_fields',
        action: 'add_field',
    });

    return APIRequest({
        method: 'post',
        url:
            apiUrl +
            `admin/settings/contract-custom-section/${additional_info_fields_category_id}/fields`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateContractCustomField = (
    additional_info_fields_category_id: number,
    additional_info_field_id: number,
    payload: any
) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'custom_section_fields',
        action: 'edit_field',
    });

    return APIRequest({
        method: 'put',
        url:
            apiUrl +
            `admin/settings/contract-custom-section/${additional_info_fields_category_id}/field/${additional_info_field_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteContractCustomField = (
    additional_info_fields_category_id: number,
    additional_info_field_id: number
) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'custom_section_fields',
        action: 'delete_field',
    });

    return APIRequest({
        method: 'delete',
        url:
            apiUrl +
            `admin/settings/contract-custom-section/${additional_info_fields_category_id}/field/${additional_info_field_id}`,
        useDefaultErrorNotif: true,
    });
};

export const addNewDropdownOption = (
    additional_info_fields_category_id: number,
    additional_info_field_id: number,
    option_value: string
) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'custom_section_fields',
        action: 'add_dropdown_option',
    });

    return APIRequest({
        method: 'post',
        url:
            apiUrl +
            `admin/settings/contract-custom-section/${additional_info_fields_category_id}/field/${additional_info_field_id}/options`,
        data: { option_value },
        useDefaultErrorNotif: true,
    });
};

export const updateDropdownOption = (
    additional_info_fields_category_id: number,
    additional_info_field_id: number,
    additional_info_fields_option_id: number,
    option_value: string
) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'custom_section_fields',
        action: 'edit_dropdown_option',
    });

    return APIRequest({
        method: 'put',
        url:
            apiUrl +
            `admin/settings/contract-custom-section/${additional_info_fields_category_id}/field/${additional_info_field_id}/option/${additional_info_fields_option_id}`,
        data: { option_value },
        useDefaultErrorNotif: true,
    });
};

export const deleteDropdownOption = (
    additional_info_fields_category_id: number,
    additional_info_field_id: number,
    additional_info_fields_option_id: number
) => {
    return APIRequest({
        method: 'delete',
        url:
            apiUrl +
            `admin/settings/contract-custom-section/${additional_info_fields_category_id}/field/${additional_info_field_id}/option/${additional_info_fields_option_id}`,
        useDefaultErrorNotif: true,
    });
};
// End of custom section and fields API call

// Custom Document Folder APIs
export const getDocumentFolders = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'documents/folders',
        useDefaultErrorNotif: true,
    });
};

export const addNewDocumentFolder = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'document_templates',
        action: 'add_folder',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/document-folders`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const addNewDocumentTemplate = (folder_id?: number, payload?: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'document_templates',
        action: 'add_doc_template',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/document-folder/${folder_id}/template`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateDocumentFolder = (folder_id: number, payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'document_templates',
        action: 'edit_folder',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/document-folder/${folder_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateTemplateData = (
    folder_id?: number,
    docs_template_id?: number,
    payload?: any
) => {
    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/document-folder/${folder_id}/template/${docs_template_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteDocumentFolder = (folder_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'document_templates',
        action: 'delete_folder',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `admin/settings/document-folder/${folder_id}`,
        useDefaultErrorNotif: true,
    });
};

export const deleteDocumentTemplate = (folder_id: number, docs_template_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'transaction',
        sub_section: 'document_templates',
        action: 'delete_folder',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `admin/settings/document-folder/${folder_id}/template/${docs_template_id}`,
        useDefaultErrorNotif: true,
    });
};

// End of Custom Document Folder APIs

// Sub User APIs
export const getSubUsers = (params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'admin/sub-users' + (params ? `?${params}` : ''),
        useDefaultErrorNotif: true,
    });
};

export const addNewSubUser = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'team_access_management',
        sub_section: 'sub_users',
        action: 'add_user',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/sub-users`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateSubUser = (account_id: number, payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'team_access_management',
        sub_section: 'sub_users',
        action: 'edit_user',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/sub-user/${account_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteSubUser = (account_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'team_access_management',
        sub_section: 'sub_users',
        action: 'delete_user',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `admin/sub-user/${account_id}`,
        useDefaultErrorNotif: true,
    });
};

export const resendInvitation = (account_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'team_access_management',
        sub_section: 'sub_users',
        action: 'resend_invitation',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/sub-user/${account_id}/resend-invitation`,
        useDefaultErrorNotif: true,
    });
};

export const getSubUserPermission = (account_id: number) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `admin/sub-user/${account_id}/permission`,
        useDefaultErrorNotif: true,
    });
};

export const getMaxNumberOfSubUsers = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'admin/max-no-of-sub-users',
    });
};

export const updateSubUserPermission = (account_id: number, payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'team_access_management',
        sub_section: 'sub_users',
        action: 'edit_permission',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/sub-user/${account_id}/permission`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};
// End of Sub User APIs

// Office APIs
export const addNewOffice = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'team_access_management',
        sub_section: 'offices',
        action: 'add_office',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `offices`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateOffice = (office_id: number, payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'team_access_management',
        sub_section: 'offices',
        action: 'edit_office',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `office/${office_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteOffice = (office_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'team_access_management',
        sub_section: 'offices',
        action: 'delete_office',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `office/${office_id}`,
        useDefaultErrorNotif: true,
    });
};
// End of Office APIs
