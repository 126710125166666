// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Alert,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Menu,
    Popconfirm,
    Row,
    Space,
    Switch,
    Tag,
    Typography,
    Dropdown,
    Modal,
} from 'antd';
import {
    CheckSquareOutlined,
    CloseOutlined,
    CopyOutlined,
    DeleteFilled,
    DeleteOutlined,
    DownOutlined,
    EditFilled,
    EllipsisOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    InfoCircleOutlined,
    PlusOutlined,
    ReloadOutlined,
    SaveFilled,
    UpOutlined,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import debounce from 'lodash/debounce';

// EZ web-app utils import
import { showMessage, showNotification } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    addNewDefaultTaskData,
    addNewDefaultTaskTab,
    bulkUpdateDefaultTaskPositions,
    deleteDefaultTask,
    deleteDefaultTaskTab,
    duplicateTaskTemplate,
    getContractCustomFields,
    getTaskTabsDetail,
    updateDefaultTaskData,
    updateDefaultTaskTabData,
} from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import { DefaultTaskTabType, DefaultTaskType } from 'types/admin';
import { AccountCompactDataType } from 'types/account';
import { getAllTeamMembers } from 'utils/ez_api/memberAPIs';
import { getFoldersWithEmailTemplates } from 'utils/ez_api/emailTemplateAPIs';
import { EmailTemplateFolderType } from 'types/emailTemplate';
import { HomeContext } from 'context/EZContext';
import { EZTable } from 'components';
import { contractAdditionalInfoCategoryType } from 'types/transaction';
import { flatten, pickBy } from 'lodash';
import { SELECT_OPTGROUP_FILTEROPT } from 'utils/commonUtil';
import { updateOnboardingStep } from 'utils/onboardingUtil';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export const AUTO_DUEDATE_NAMES_BASIC = [
    {
        label: 'Contract Date',
        value: 'contract_date',
    },
    {
        label: 'Close Date',
        value: 'close_date',
    },
    {
        label: 'Expiration Date',
        value: 'expiration_date',
    },
    {
        label: 'Inspection Contingency Date',
        value: 'inspection_contingency_date',
    },
    {
        label: 'Appraisal Contingency Date',
        value: 'appraisal_contingency_date',
    },
    {
        label: 'Financing Contingency Date',
        value: 'financing_contingency_date',
    },
    {
        label: 'Listing Date',
        value: 'listing_date',
    },
    {
        label: 'Effective Date',
        value: 'effective_date',
    },
    {
        label: 'Listing Live Date',
        value: 'listing_live_date',
    },
    {
        label: 'Application Date',
        value: 'application_date',
    },
    {
        label: 'Lease Signed Date',
        value: 'lease_signed_date',
    },
    {
        label: 'Lease Start',
        value: 'lease_start',
    },
    {
        label: 'Lease End',
        value: 'lease_end',
    },
    {
        label: 'Lease Term',
        value: 'lease_term',
    },
    {
        label: 'Move In Date',
        value: 'move_in_date',
    },
];

type DateAlertOption = {
    label: string;
    value: string;
};

type DateAlertGroupOption = {
    label: string;
    options: DateAlertOption[];
};

const CUSTOM_FIELD_DATE_REGEX = /FIELD-ID_(\d+)_(\d+)/;

const generateCustomFieldDateValue = (fieldCategoryID: number, fieldID: number) => {
    return `FIELD-ID_${fieldCategoryID}_${fieldID}`;
};

const parseCustomFieldDateValue = (customFieldDateValue: string) => {
    const result = customFieldDateValue.match(CUSTOM_FIELD_DATE_REGEX);
    if (result && result.length === 3) {
        return {
            fieldCategoryID: result?.[1],
            fieldID: result?.[2],
        };
    } else
        return {
            fieldCategoryID: null,
            fieldID: null,
        };
};

// EZ web-app components import

// EZ web-app styles and assets import

export const TaskSetting: React.FC<{}> = ({}) => {
    const HomeCtx = useContext(HomeContext);
    const UserInfo = HomeCtx.userInfo;

    const [form] = Form.useForm();

    const [isFetchingTaskTabs, setIsFetchingTaskTabs] = useState(false);
    const [taskTabs, setTaskTabs] = useState<DefaultTaskTabType[]>([]);
    const [taskTabsBuffer, setTaskTabsBuffer] = useState<DefaultTaskTabType[]>([]);

    const [expandedRecordIDs, setExpandedRecordIDs] = useState<React.Key[]>([]);
    const [editableKeys, setEditableKeys] = useState<React.Key[]>([]);

    const [teamMembers, setTeamMembers] = useState<AccountCompactDataType[]>([]);
    const [emailTemplateFolders, setEmailTemplateFolders] = useState<EmailTemplateFolderType[]>([]);

    const [isFetchingSections, setIsFetchingSections] = useState(false);
    const [dateAlertGroupOptions, setDateAlertGroupOptions] = useState<DateAlertGroupOption[]>([]);
    const [dateAlertOptionsFlatten, setDateAlertOptionsFlatten] = useState<DateAlertOption[]>([]);

    const moreActions: ItemType[] = [
        {
            label: 'Duplicate',
            key: 'duplicate_task_template',
            icon: <CopyOutlined />,
        },
    ];

    const _fetchTaskTabsDetail = () => {
        setIsFetchingTaskTabs(true);
        getTaskTabsDetail()
            .then(({ data }) => {
                const respData = data as DefaultTaskTabType[];
                setTaskTabs(respData || []);
                setTaskTabsBuffer(respData || []);
            })
            .finally(() => {
                setIsFetchingTaskTabs(false);
            });
    };

    const _fetchCustomSections = () => {
        setIsFetchingSections(true);
        getContractCustomFields()
            .then(({ data }) => {
                const customSections: contractAdditionalInfoCategoryType[] = data;
                const customSectionsGroupOpts: DateAlertGroupOption[] = [];
                for (let index = 0; index < customSections.length; index++) {
                    const customSection = customSections[index];

                    const customSectionOpts: DateAlertOption[] = [];
                    for (
                        let ii = 0;
                        ii < customSection.contract_additional_info_preferences.length;
                        ii++
                    ) {
                        const field = customSection.contract_additional_info_preferences[ii];
                        if (field.contract_additional_info_field.class === 'date') {
                            customSectionOpts.push({
                                label: `${field.newname}`,
                                value: generateCustomFieldDateValue(
                                    field.additional_info_fields_category_id,
                                    field.additional_info_field_id
                                ),
                            });
                        }
                    }
                    if (customSectionOpts.length) {
                        customSectionsGroupOpts.push({
                            label: `${customSection.category}`,
                            options: customSectionOpts,
                        });
                    }
                }
                setDateAlertOptionsFlatten([
                    ...AUTO_DUEDATE_NAMES_BASIC,
                    ...customSectionsGroupOpts
                        .map(gOpts => {
                            return gOpts.options;
                        })
                        .flat(),
                ]);
                setDateAlertGroupOptions([
                    { label: 'Common Dates', options: AUTO_DUEDATE_NAMES_BASIC },
                    ...customSectionsGroupOpts,
                ]);
            })
            .finally(() => {
                setIsFetchingSections(false);
            });
    };

    const _doActionTaskMenu = (menuKey: string, record: DefaultTaskTabType) => {
        switch (menuKey) {
            case 'duplicate_task_template':
                const duplicateConfirmationModal = Modal.confirm({
                    title: `Duplicate Confirmation`,
                    width: 600,
                    content: (
                        <>
                            <p>
                                Are you sure to duplicate task template: <b>{record.name}</b>?
                            </p>
                        </>
                    ),
                    okText: 'Duplicate',
                    onOk: () => {
                        duplicateConfirmationModal.update({
                            closable: false,
                            maskClosable: false,
                            okButtonProps: {
                                disabled: true,
                            },
                            okText: 'Duplicating...',
                        });

                        return duplicateTaskTemplate(record.defaulttasktab_id).then((res: any) => {
                            showNotification(
                                'success',
                                `You have successfully duplicated task template: ${record.name}`
                            );

                            addNewTaskTab(res.data);

                            return true;
                        });
                    },
                    closable: true,
                    maskClosable: true,
                });
                break;
            default:
                break;
        }
    };

    const columns: ProColumns<DefaultTaskTabType>[] = [
        {
            title: 'On/Off',
            dataIndex: 'toggle',
            key: 'toggle',
            width: '80px',
            editable: false,
            render: (_, record) => (
                <Form.Item
                    name={[`${record.defaulttasktab_id}`, 'toggle']}
                    noStyle
                    valuePropName="checked"
                    initialValue={
                        !record.defaulttasktab_preference ||
                        record.defaulttasktab_preference?.toggle
                            ? true
                            : false
                    }
                >
                    <Switch
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                        defaultChecked={false}
                        onChange={(val: boolean) => {
                            _saveTaskTabData(record, {
                                toggle: val,
                            });
                        }}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'List Name',
            dataIndex: 'name',
            key: 'name',
            width: '280px',
            formItemProps: (form, config) => {
                return {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                };
            },
            render: (_, record) => (
                <Typography.Text
                    onClick={() => {
                        if (!expandedRecordIDs.includes(record.defaulttasktab_id))
                            setExpandedRecordIDs([record.defaulttasktab_id]);
                    }}
                    strong
                    underline
                >
                    {record.name}
                </Typography.Text>
            ),
        },
        {
            title: 'Tasklist Information',
            dataIndex: '',
            key: 'x',
            editable: false,
            render: (_, record) => (
                <Tag
                    onClick={() => {
                        if (!expandedRecordIDs.includes(record.defaulttasktab_id))
                            setExpandedRecordIDs([record.defaulttasktab_id]);
                    }}
                    className="tag-rounded"
                >
                    {record.defaulttasks?.length} Task(s)
                </Tag>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '250px',
            key: 'x',
            valueType: 'option',
            render: (_, record, index, action) => (
                <span>
                    <Button
                        className="p-0"
                        type="link"
                        icon={<EditFilled />}
                        title="Edit list name"
                        size="small"
                        onClick={() => {
                            action?.startEditable(record.defaulttasktab_id);
                        }}
                    >
                        Edit List Name
                    </Button>

                    <Divider type="vertical" />

                    <Popconfirm
                        placement="bottomLeft"
                        title={
                            <>
                                Delete <b>{record.name}</b>?
                            </>
                        }
                        onConfirm={() => _deleteSelectedTaskTab(record.defaulttasktab_id)}
                    >
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<DeleteOutlined />}
                            title="Delete this list"
                            size="small"
                            danger
                        ></Button>
                    </Popconfirm>

                    <Divider type="vertical" />

                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu
                                items={moreActions}
                                onClick={({ key }) => _doActionTaskMenu(key, record)}
                            />
                        }
                        placement="bottomRight"
                    >
                        <span onClick={e => e.preventDefault()}>
                            <Button
                                className="p-0"
                                type="link"
                                icon={<EllipsisOutlined />}
                                title="More action"
                            ></Button>
                        </span>
                    </Dropdown>
                </span>
            ),
        },
    ];

    const modifyTaskTabData = (taskTabID: number, newRecordData: any) => {
        try {
            const taskTabIndex = taskTabs.findIndex(t => t.defaulttasktab_id === taskTabID);

            if (taskTabIndex !== undefined && taskTabIndex >= 0) {
                taskTabs[taskTabIndex] = { ...taskTabs[taskTabIndex], ...newRecordData };
                setTaskTabs([...taskTabs]);
            }
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx ~ line 288 ~ modifyTaskTabData ~ error', error);
        }
    };

    const deleteTaskList = (taskListID: number) => {
        try {
            const taskListIndex = taskTabs.findIndex(t => t.defaulttasktab_id === taskListID);

            if (taskListIndex !== undefined && taskListIndex >= 0) {
                taskTabs.splice(taskListIndex, 1);
                setTaskTabs([...taskTabs]);
            }
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx ~ line 292 ~ deleteTaskList ~ error', error);
        }
    };

    const addNewTaskTab = (taskTabData: DefaultTaskTabType) => {
        try {
            taskTabs.unshift(taskTabData);
            setTaskTabs([...taskTabs]);
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx ~ line 267 ~ deleteTask ~ error', error);
        }
    };

    const _saveTaskTabData = (TaskTab: DefaultTaskTabType, newRecordData: any) => {
        setTimeout(() => {
            modifyTaskTabData(TaskTab.defaulttasktab_id, { ...TaskTab, ...newRecordData });
        }, 0);
        return updateDefaultTaskTabData(TaskTab.defaulttasktab_id, newRecordData).then(() => {
            showMessage('success', 'Selected task list data has been updated.');
            return true;
        });
    };

    const _deleteSelectedTaskTab = (taskTabID: number) => {
        return deleteDefaultTaskTab(taskTabID)
            .then(() => {
                showMessage('success', 'Selected task list has been deleted.');
                deleteTaskList(taskTabID);
            })
            .catch(() => {});
    };

    const _saveNewTaskTab = () => {
        const payload = {
            name: 'Untitled',

            toggle: false,
        };

        return addNewDefaultTaskTab(payload)
            .then(({ data }) => {
                showMessage('success', `New task list: "Untitled" has been added.`);
                addNewTaskTab(data);
                // update onboarding step to completed
                updateOnboardingStep('add_new_task_template', HomeCtx.refetchOnboardingList);
            })
            .catch(() => {});
    };

    const ExpandedRowComponent: React.FC<{ TaskTab: DefaultTaskTabType }> = ({ TaskTab }) => {
        const [editableKeysTask, setEditableKeysTask] = useState<React.Key[]>([]);
        const [tabTasks, setTabTasks] = useState<DefaultTaskType[]>(TaskTab.defaulttasks || []);

        const deleteTask = (taskID: number) => {
            try {
                const taskIndex = tabTasks.findIndex(t => t.defaulttask_id === taskID);

                if (taskIndex !== undefined && taskIndex >= 0) {
                    tabTasks.splice(taskIndex, 1);
                    setTabTasks([...tabTasks]);
                }
            } catch (error) {
                console.log('🚀 ~ file: taskSetting.tsx:418 ~ deleteTask ~ error:', error);
            }
        };

        const _deleteSelectedTask = (TaskTab: DefaultTaskTabType, taskID: number) => {
            return deleteDefaultTask(TaskTab.defaulttasktab_id, taskID)
                .then(() => {
                    showMessage('success', 'Selected task has been deleted.');

                    deleteTask(taskID);
                })
                .catch(() => {});
        };

        const addNewTask = (taskData: DefaultTaskType) => {
            try {
                tabTasks.unshift(taskData);
                setTabTasks([...tabTasks]);

                setEditableKeysTask([...editableKeysTask, taskData.defaulttask_id]);
            } catch (error) {
                console.log('🚀 ~ file: taskSetting.tsx ~ line 267 ~ deleteTask ~ error', error);
            }
        };

        const _saveNewTask = () => {
            const defaulttask_id_dummy = Date.now();

            const payload = {
                title: 'Untitled',
                position_order: tabTasks.length ? (tabTasks[0].position_order || 0) - 1 : -1,

                toggle: true,
            };

            return addNewDefaultTaskData(TaskTab.defaulttasktab_id, payload)
                .then(({ data }) => {
                    showMessage(
                        'success',
                        `New task: "Untitled" has been added to task list: "${TaskTab.name}".`
                    );

                    addNewTask(data);
                })
                .catch(() => {
                    deleteTask(defaulttask_id_dummy);
                });
        };

        const _saveTaskData = (
            TaskTab: DefaultTaskTabType,
            task: DefaultTaskType,
            newRecordData: any
        ) => {
            return updateDefaultTaskData(
                TaskTab.defaulttasktab_id,
                task.defaulttask_id,
                newRecordData
            ).then(() => {
                modifyTaskData(task.defaulttask_id, { ...task, ...newRecordData });

                showMessage('success', 'Selected task data has been updated.');
                return true;
            });
        };

        const modifyTaskData = (taskID: number, newRecordData: any) => {
            try {
                const taskIndex = tabTasks?.findIndex(t => t.defaulttask_id === taskID);

                if (taskIndex !== undefined && taskIndex >= 0 && tabTasks[taskIndex]) {
                    tabTasks![taskIndex] = {
                        ...tabTasks[taskIndex],
                        ...newRecordData,
                    };

                    if (tabTasks[taskIndex]?.defaulttasksalert) {
                        if ('num_days' in newRecordData) {
                            tabTasks![taskIndex].defaulttasksalert!.num_days =
                                typeof newRecordData.num_days === 'number'
                                    ? newRecordData.num_days
                                    : null;
                        }
                        if ('before_after' in newRecordData) {
                            tabTasks![taskIndex].defaulttasksalert!.before_after =
                                newRecordData.before_after || null;
                        }
                        if ('date' in newRecordData) {
                            tabTasks![taskIndex].defaulttasksalert!.date =
                                newRecordData.date || null;
                        }
                    } else if (newRecordData?.defaulttasksalert) {
                        tabTasks[taskIndex].defaulttasksalert = newRecordData.defaulttasksalert;
                    }

                    if (newRecordData.toggle === false) {
                        tabTasks![taskIndex].defaulttask_preference = {
                            defaulttask_id: taskID.toString(),
                            defaulttasks_preference_id:
                                tabTasks![taskIndex].defaulttask_preference
                                    ?.defaulttasks_preference_id || -1,
                            defaulttasktab_id: TaskTab.defaulttasktab_id,
                            toggle: 0,
                            parent_account_id: TaskTab.parent_account_id,
                        };
                    } else {
                        tabTasks![taskIndex].defaulttask_preference = undefined;
                    }

                    setTabTasks([...tabTasks]);
                }
            } catch (error) {
                console.log('🚀 ~ file: taskSetting.tsx:400 ~ modifyTaskData ~ error:', error);
            }
        };

        const _moveUp = async (index: number, taskRecord: DefaultTaskType) => {
            let seen = new Set();
            // check if in tab there is any duplicated position order
            // will regroup the position order if found any
            const isPositionOrderDuplicate = tabTasks.some(function (currentObject) {
                return seen.size === seen.add(currentObject.position_order).size;
            });
            if (isPositionOrderDuplicate) {
                const payload = tabTasks.map((task: DefaultTaskType, idx: number) => {
                    tabTasks[idx].position_order = idx;
                    return {
                        defaulttask_id: task.defaulttask_id,
                        position_order: idx,
                    };
                });
                await bulkUpdateDefaultTaskPositions(TaskTab.defaulttasktab_id, payload);
            }

            let tempTaskRecord = taskRecord;
            tabTasks[index] = tabTasks[index - 1];
            tabTasks[index - 1] = tempTaskRecord;

            const tempPositionOrder = tabTasks[index].position_order;
            tabTasks[index].position_order = tabTasks[index - 1].position_order;
            tabTasks[index - 1].position_order = tempPositionOrder;

            setTabTasks([...tabTasks]);

            // update the corresponding order positions
            await bulkUpdateDefaultTaskPositions(TaskTab.defaulttasktab_id, [
                {
                    defaulttask_id: tabTasks[index].defaulttask_id,
                    position_order: tabTasks[index].position_order ?? index,
                },
                {
                    defaulttask_id: tabTasks[index - 1].defaulttask_id,
                    position_order: tabTasks[index - 1].position_order ?? index - 1,
                },
            ]);
        };

        const _moveDown = async (index: number, taskRecord: DefaultTaskType) => {
            let seen = new Set();
            // check if in tab there is any duplicated position order
            // will regroup the position order if found any
            const isPositionOrderDuplicate = tabTasks.some(function (currentObject) {
                return seen.size === seen.add(currentObject.position_order).size;
            });
            if (isPositionOrderDuplicate) {
                const payload = tabTasks.map((task: DefaultTaskType, index: number) => {
                    tabTasks[index].position_order = index;
                    return {
                        defaulttask_id: task.defaulttask_id,
                        position_order: index,
                    };
                });
                await bulkUpdateDefaultTaskPositions(TaskTab.defaulttasktab_id, payload);
            }
            let tempTaskRecord = taskRecord;
            tabTasks[index] = tabTasks[index + 1];
            tabTasks[index + 1] = tempTaskRecord;

            const tempPositionOrder = tabTasks[index].position_order;
            tabTasks[index].position_order = tabTasks[index + 1].position_order;
            tabTasks[index + 1].position_order = tempPositionOrder;

            setTabTasks([...tabTasks]);

            // update the corresponding order positions
            await bulkUpdateDefaultTaskPositions(TaskTab.defaulttasktab_id, [
                {
                    defaulttask_id: tabTasks[index].defaulttask_id,
                    position_order: tabTasks[index].position_order ?? index,
                },
                {
                    defaulttask_id: tabTasks[index + 1].defaulttask_id,
                    position_order: tabTasks[index + 1].position_order ?? index + 1,
                },
            ]);
        };

        const taskColumns: ProColumns<DefaultTaskType>[] = [
            {
                title: 'Order',
                dataIndex: 'position_order',
                key: 'position_order',
                width: '68px',
                editable: false,
                render(dom, entity, index, action, schema) {
                    return (
                        <Space>
                            <Button
                                icon={<DownOutlined />}
                                size="small"
                                disabled={index === tabTasks.length - 1}
                                onClick={() => {
                                    _moveDown(index, entity);
                                }}
                            />
                            <Button
                                icon={<UpOutlined />}
                                size="small"
                                disabled={index === 0}
                                onClick={() => {
                                    _moveUp(index, entity);
                                }}
                            />
                        </Space>
                    );
                },
            },
            {
                title: 'On/Off',
                dataIndex: 'toggle',
                key: 'toggle',
                width: '55px',
                editable: false,
                render(dom, entity, index, action, schema) {
                    return (
                        <Switch
                            size="small"
                            checkedChildren={<EyeOutlined />}
                            unCheckedChildren={<EyeInvisibleOutlined />}
                            defaultChecked={
                                !entity.defaulttask_preference ||
                                entity.defaulttask_preference?.toggle
                                    ? true
                                    : false
                            }
                            onChange={(val: boolean) => {
                                setTimeout(() => {
                                    _saveTaskData(TaskTab, entity, {
                                        toggle: val,
                                    });
                                }, 500);
                            }}
                        />
                    );
                },
            },
            {
                title: 'Task Title',
                dataIndex: 'title',
                key: 'title',
                width: '360px',
                fieldProps: (_, config) => {
                    return {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {},
                    };
                },
                formItemProps: (form, config) => {
                    return {
                        rules: [
                            {
                                required: true,
                            },
                        ],
                    };
                },
            },
            {
                title: 'Due Date',
                dataIndex: 'num_days',
                key: 'num_days',
                width: '140px',
                valueType: 'digit',
                formItemProps: (form, config) => {
                    return {
                        initialValue: config.entity.defaulttasksalert?.num_days,
                    };
                },
                fieldProps: (_, config) => {
                    return {
                        placeholder: 'Day(s)',
                        addonAfter: 'Day(s)',
                        style: { width: '140px' },
                    };
                },
                render: (_, record) => (
                    <span>
                        {typeof record.defaulttasksalert?.num_days === 'number' &&
                        record.defaulttasksalert?.before_after &&
                        record.defaulttasksalert?.date
                            ? `${record.defaulttasksalert?.num_days} Day(s)`
                            : '-'}
                    </span>
                ),
                colSpan: 3,
            },
            {
                title: 'before_after',
                dataIndex: 'before_after',
                key: 'before_after',
                width: 94,
                valueType: 'select',
                formItemProps: (_, config) => {
                    return {
                        initialValue: config.entity.defaulttasksalert?.before_after,
                    };
                },
                fieldProps: (_, config) => {
                    return {
                        style: {
                            width: 86,
                        },
                        placeholder: 'Select',
                        options: [
                            {
                                label: 'Before',
                                value: 'before',
                            },
                            {
                                label: 'After',
                                value: 'after',
                            },
                        ],
                        onChange: (val: string) => {},
                    };
                },
                render: (_, record) => (
                    <span>
                        {typeof record.defaulttasksalert?.num_days === 'number' &&
                        record.defaulttasksalert?.before_after &&
                        record.defaulttasksalert?.date ? (
                            <span style={{ textTransform: 'capitalize' }}>
                                {record.defaulttasksalert.before_after}
                            </span>
                        ) : (
                            '-'
                        )}
                    </span>
                ),
                colSpan: 0,
            },
            {
                title: 'date',
                dataIndex: 'date',
                key: 'date',
                width: 220,
                valueType: 'select',
                formItemProps: (_, config) => {
                    return {
                        initialValue: config.entity.defaulttasksalert?.date,
                    };
                },
                fieldProps: (_, config) => {
                    return {
                        style: {
                            width: 220,
                        },
                        placeholder: 'Select Date Type',
                        options: dateAlertGroupOptions,
                        showSearch: true,
                        filterOption: SELECT_OPTGROUP_FILTEROPT,
                        onChange: (val: string) => {},
                    };
                },
                render: (_, record) => {
                    let dateName = '-';
                    if (
                        typeof record.defaulttasksalert?.num_days === 'number' &&
                        record.defaulttasksalert?.before_after &&
                        record.defaulttasksalert?.date
                    ) {
                        if (record.defaulttasksalert?.date.includes('FIELD-ID')) {
                            const optObj = dateAlertOptionsFlatten.find(
                                opt => opt.value === record.defaulttasksalert?.date
                            );
                            if (optObj) {
                                dateName = optObj.label;
                            }
                        } else {
                            dateName = record.defaulttasksalert.date?.replaceAll('_', ' ');
                        }
                    }
                    return <span style={{ textTransform: 'capitalize' }}>{dateName}</span>;
                },
                colSpan: 0,
            },
            {
                title: 'Assignee',
                dataIndex: 'assignee',
                key: 'assignee',
                width: '180px',
                valueType: 'select',
                fieldProps: (_, config) => {
                    return {
                        style: {
                            maxWidth: '180px',
                            width: '180px',
                        },
                        placeholder: 'Select Assignee',
                        showSearch: true,
                        options: teamMembers?.map(user => ({
                            label: user.fullname,
                            value: user.account_id,
                        })),
                        onChange: (val: string) => {},
                    };
                },
                formItemProps: (form, config) => {
                    return {
                        initialValue: config.entity.assignee,
                    };
                },
            },
            {
                title: 'Email Template',
                dataIndex: 'email_template_id',
                key: 'email_template_id',
                width: '220px',
                valueType: 'select',
                fieldProps: (_, config) => {
                    return {
                        style: {
                            maxWidth: '220px',
                            width: '220px',
                        },
                        placeholder: 'Select Template',
                        options: emailTemplateFolders?.map(folder => ({
                            label: `${folder.folder_name}${
                                folder.is_shared && folder.account_id !== UserInfo?.account_id
                                    ? ` - Shared by ${folder?.account?.fullname}`
                                    : ``
                            }`,
                            options: folder.email_templates?.map(emailTemplate => ({
                                label: emailTemplate.template_name,
                                value: emailTemplate.email_template_id,
                            })),
                        })),
                        showSearch: true,
                        filterOption: SELECT_OPTGROUP_FILTEROPT,
                        onChange: (val: string) => {},
                    };
                },
                formItemProps: (form, config) => {
                    return {
                        initialValue: config.entity.email_template_id,
                    };
                },
            },
            {
                title: 'Action',
                width: '140px',
                dataIndex: '',
                valueType: 'option',
                fixed: 'right',
                render: (_, record, index, action) => (
                    <span>
                        <Button
                            className="p-0"
                            type="link"
                            icon={<EditFilled />}
                            title="Edit this row"
                            size="small"
                            onClick={() => {
                                setEditableKeysTask([...editableKeysTask, record.defaulttask_id]);
                            }}
                        >
                            Edit
                        </Button>
                    </span>
                ),
            },
        ];

        return (
            <div className="expanded-row-with-table">
                <div>
                    <Alert
                        message={
                            <>
                                Tip: Click the <b>Edit</b> button, and then hit the{' '}
                                <SaveFilled style={{ color: '#1890ff' }} /> icon to save your
                                changes when editing task's title, due date, assignee, and/or linked
                                email template.
                            </>
                        }
                        type="info"
                        showIcon
                        className="mb-3"
                    />
                </div>
                <ProTable
                    className="mt-3"
                    size="small"
                    rowKey="defaulttask_id"
                    pagination={{ pageSize: 100 }}
                    showSorterTooltip={false}
                    search={false}
                    columns={taskColumns}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                    scroll={{ x: 1800, y: 600 }}
                    dataSource={tabTasks}
                    editable={{
                        editableKeys: editableKeysTask,
                        type: 'multiple',
                        onSave: async (key, newData: any, oldData) => {
                            if ('num_days' in newData) {
                                newData.num_days =
                                    typeof newData.num_days === 'number' ? newData.num_days : null;
                            }
                            if ('before_after' in newData) {
                                newData.before_after = newData.before_after || null;
                            }
                            if ('date' in newData) {
                                newData.date = newData.date || null;
                            }
                            if ('assignee' in newData) {
                                newData.assignee = newData.assignee || null;
                            }
                            if ('email_template_id' in newData) {
                                newData.email_template_id = newData.email_template_id || null;
                            }
                            return _saveTaskData(TaskTab, newData, newData).then(() => {
                                setEditableKeysTask([
                                    ...editableKeysTask.filter(keyItem => keyItem !== key),
                                ]);
                                return true;
                            });
                        },
                        onDelete: (_, row) => {
                            return _deleteSelectedTask(TaskTab, row.defaulttask_id);
                        },
                        onCancel: (key, record, originRow, newLineConfig) => {
                            setEditableKeysTask([
                                ...editableKeysTask.filter(keyItem => keyItem !== key),
                            ]);
                            return Promise.resolve(true);
                        },
                        saveText: (
                            <>
                                <Button
                                    className="p-0 pl-1"
                                    type="link"
                                    icon={<SaveFilled />}
                                ></Button>
                            </>
                        ),
                        deleteText: (
                            <>
                                <Button
                                    className="p-0"
                                    type="link"
                                    icon={<DeleteFilled />}
                                    title="Delete this task?"
                                    size="small"
                                    danger
                                ></Button>
                            </>
                        ),
                        deletePopconfirmMessage: 'Delete this task?',
                        cancelText: (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0 text-grey"
                                    type="text"
                                    title="Cancel and back"
                                    icon={<CloseOutlined />}
                                ></Button>
                            </>
                        ),
                    }}
                    toolBarRender={() => [
                        <Button size="small" key="3" type="primary" onClick={() => _saveNewTask()}>
                            <CheckSquareOutlined />
                            New Task
                        </Button>,
                    ]}
                />
            </div>
        );
    };

    const _fetchTeamMembers = () => {
        getAllTeamMembers().then(({ data }) => {
            setTeamMembers((data as AccountCompactDataType[]) || []);
        });
    };

    const _fetchEmailTemplates = () => {
        getFoldersWithEmailTemplates().then(({ data }) => {
            setEmailTemplateFolders(data);
        });
    };

    const _fetchData = () => {
        _fetchTaskTabsDetail();
        _fetchTeamMembers();
        _fetchEmailTemplates();

        _fetchCustomSections();
    };

    useEffect(() => {
        _fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form layout="vertical" form={form} requiredMark={false}>
            <Row>
                <Col span={18} style={{ textAlign: 'left' }} className="text-help">
                    <InfoCircleOutlined /> Tip: Check the box in the On/Off column to have this task
                    list automatically added to all <b>new transactions</b>.
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <Button
                        className="ez-action-btn mr-3"
                        icon={<ReloadOutlined />}
                        onClick={() => {
                            _fetchData();
                        }}
                        loading={isFetchingTaskTabs}
                        disabled={isFetchingTaskTabs}
                    >
                        Reload
                    </Button>
                    <Button key="3" type="primary" onClick={() => _saveNewTaskTab()}>
                        <PlusOutlined />
                        New Task List
                    </Button>
                </Col>
                <Col span={24} className="mt-3">
                    <ProTable
                        rowKey="defaulttasktab_id"
                        showSorterTooltip={false}
                        search={false}
                        tableLayout="fixed"
                        // scroll={{ x: 1100 }}
                        loading={isFetchingTaskTabs}
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record: DefaultTaskTabType) => {
                                return <ExpandedRowComponent TaskTab={record} />;
                            },
                            expandedRowKeys: expandedRecordIDs,
                            expandRowByClick: false,
                            onExpand: (expanded, record: DefaultTaskTabType) => {
                                if (
                                    expanded &&
                                    !expandedRecordIDs.includes(record.defaulttasktab_id)
                                ) {
                                    setExpandedRecordIDs([record.defaulttasktab_id]);
                                } else {
                                    setExpandedRecordIDs([]);
                                }
                            },
                        }}
                        editable={{
                            actionRender: (_, __, defaultDom) => [
                                defaultDom.save,
                                defaultDom.cancel,
                            ],
                            type: 'multiple',
                            onSave: async (_, newData: DefaultTaskTabType, oldData) => {
                                return _saveTaskTabData(oldData, { name: newData.name });
                            },
                            onDelete: (key, row) => {
                                _deleteSelectedTaskTab(row.defaulttasktab_id);
                                return Promise.resolve(true);
                            },
                            saveText: (
                                <>
                                    <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                        Save
                                    </Button>
                                </>
                            ),
                            cancelText: (
                                <>
                                    <Divider type="vertical" />
                                    <Button
                                        className="p-0 text-grey"
                                        type="text"
                                        title="Cancel and back"
                                        icon={<CloseOutlined />}
                                    ></Button>
                                </>
                            ),
                        }}
                        // scroll={{ x: 2000 }}
                        dataSource={taskTabs}
                        options={{
                            fullScreen: false,
                            reload: false,
                            setting: false,
                            density: false,
                        }}
                    />
                </Col>
            </Row>
        </Form>
    );
};
